import styled from "styled-components";
import PropTypes from "prop-types";
import { css } from "styled-components";
import { darken } from "polished";

const Button = styled.button`
  border-radius: ${(props) =>
    props.theme.btnRound ? "1.5625rem" : "0"}; /* 25px */
  border: 0;
  display: inline-block;
  text-align: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  outline: none;
  color: ${(props) => props.theme.btnTextColor};
  padding: ${(props) => (props.small ? "0 1rem" : "0 1.5625rem")};
  min-width: ${(props) => (props.fullwidth ? "100%" : "")};
  background: ${(props) =>
    props.gradient &&
    `transparent linear-gradient(90deg, ${props.gradient.left} 0%, ${props.gradient.right} 100%) 0% 0%`};
    no-repeat padding-box;
  height: ${(props) => (props.small ? "30px" : "45px")};
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 15px #00000026;
  font-size: ${(props) => (props.small ? "14px" : "16px")};
  font-weight: ${(props) => (props.small ? "400" : "700")};
  text-transform: ${(props) => (props.upper ? "uppercase" : "none")};
  transition: all 0.2s;
  opacity: ${(props) => (props.disabled ? ".5" : "1")};
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${(props) =>
          props.bgcolor
            ? darken(0.1, props.bgcolor)
            : props.secondary
            ? darken(0.1, props.theme.secondary)
            : darken(0.1, props.theme.primary)};
      }
    `}

  &:hover {
    background: ${(props) =>
      props.hoverGradient &&
      `transparent linear-gradient(90deg, ${props.hoverGradient.left} 0%, ${props.hoverGradient.right} 100%) 0% 0%`};
      transition: all 0.2s;
  }
`;

Button.defaultProps = {
  color: "#000",
};

Button.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  fullwidth: PropTypes.bool,
  secondary: PropTypes.bool,
  upper: PropTypes.bool,
};

export default Button;
