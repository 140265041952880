import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const AvatarWrapper = styled.div`
  overflow: hidden;
  width: ${(props) => (props.size ? props.size : 38)}px;
  height: ${(props) => (props.size ? props.size : 38)}px;
  border-radius: 50%;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${(props) => (props.shadow ? "0px 3px 6px #00000029" : "none")};
  border: ${(props) => (props.border ? "2px solid #fff" : "none")};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Avatar = ({ size, shadow, border }) => {
  const userinfo = useSelector((state) => state.user.user.userinfo);

  return (
    <>
      {userinfo && (
        <AvatarWrapper size={size} shadow={shadow} border={border}>
          {userinfo.avatar ? (
            <img src={userinfo.avatar} alt="" />
          ) : (
            userinfo.firstname[0]
          )}
        </AvatarWrapper>
      )}
    </>
  );
};

export default Avatar;
