import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSlug } from "../../../../utils/utils";
import Dynamic from "../../Dynamics/Dynamic";
import styled from "styled-components";
import { Loader, Modal } from "../../../../components/Ui";
import { respondTo } from "../../../../theme/mixin";

const CardBagWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 25%;
  transform: translateX(-50%);
  z-index: 1;
  width: 125px;

  ${respondTo.md`
       width: 137px;
       
      
    `}

  .back-text {
    span {
      color: ${(props) => props.theme.primary};
      font-size: 24px;
      font-weight: 300;
    }
  }

  .uovo {
    text-align: center;
    position: relative;
  }
  &.halloween {
    position: absolute;
    left: 50%;
    width: 60%;
    bottom: 10%;
    text-align: center;
    top: 15%;
    transform: translateX(-50%);
    z-index: 1;

    p {
      font-size: 16px;
    }

    .back-text {
      span {
        color: ${(props) => props.theme.primary};
        font-size: 24px;
        font-weight: 300;
      }
    }
    ${respondTo.md`
    top:5%;
    bottom: 39%;`}
  }

  ${respondTo.sm`
  &.halloween {
    
      p {
      font-size: 24px;
    }
  `}
`;

const GameContent = styled.div`
  cursor: pointer;

  .loader-wrapper {
    position: relative;
    /*     left: 75px;
    top: 70px; */
  }

  img {
    width: 130px;
    &.disabled {
      opacity: 0.5;
    }

    ${respondTo.md`
      width: 137px;
      
      
    `}
  }

  .back-button {
    position: absolute;
    left: 200px;
    bottom: -10px;
  }

  ${respondTo.sm`
  &.halloween {
      height: 58%;}
  `}
  ${respondTo.md`
  &.halloween {
      height: 80%;}
  `}
  &.halloween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    ${respondTo.sm`
    height: 100%;
  `}
    img {
      width: 130px;
      cursor: pointer;
      &.disabled {
        opacity: 0.5;
      }
    }

    .back-button {
      position: absolute;
      left: 200px;
      bottom: -10px;
    }
  }
`;

const Click = (props) => {
  const {
    halloween,
    sempre,
    type,
    onShowResult,
    typeInteraction,
    g: {
      id,
      i18l,
      interaction,
      preinteraction,
      gamePrivacyAccepted,
      log: { sid },
    },
    action: { data: dataAction, loading },
    canPlay,
    remaining_attempts,
  } = props;
  const loaderIw = useSelector((state) => state.game.loadingIw);
  const [attemptsError, setAttemptsError] = useState(false);
  const [finalCards, setFinalCards] = useState([]);
  const [action, setAction] = useState({ status: false, value: null });
  const [loader, setLoader] = useState(false);

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === "preinteraction") {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  useEffect(() => {
    if (dataAction.length > 0) {
      setTimeout(() => {
        onShowResult();
      }, 200);
    }
  }, [dataAction]);

  const clickCardHandler = (index) => {
    setAction({ status: true, value: index });
  };
  return (
    <>
      <CardBagWrapper className={halloween ? "halloween" : ""}>
        <GameContent className={halloween ? "halloween" : ""}>
          {loaderIw ? (
            // {loaderIw ? (
            <div className="loader-wrapper">
              <Loader show={loaderIw} />
            </div>
          ) : (
            <>
              {gamePrivacyAccepted && halloween && (
                <p
                  style={{
                    color: "#3F943A",
                    marginBottom: "15px",
                  }}
                >
                  Clicca sulla porta e scopri subito se hai vinto.
                </p>
              )}

              <img
                className={`back-bag ${!canPlay ? "disabled" : null}`}
                src={`${process.env.PUBLIC_URL}/images/context_top_to_play_lunedi.svg`}
                alt=""
                onClick={
                  remaining_attempts && remaining_attempts > 0
                    ? canPlay
                      ? () => clickCardHandler(0)
                      : null
                    : () => setAttemptsError(true)
                }
              />
            </>
          )}
        </GameContent>

        {action.status ? (
          <Dynamic
            id={id}
            sid={sid}
            slug={slug}
            section={typeInteraction}
            action={props.action}
            {...props}
            prizes={action.value}
          />
        ) : null}
      </CardBagWrapper>
      <Modal show={attemptsError} close={() => setAttemptsError(false)}>
        <p>
          Per oggi hai terminato i tentativi di gioco. Puoi riprovare{" "}
          {halloween
            ? "domani"
            : new Date().getDay() === +process.env.REACT_APP_THURSDAY_DAY
            ? "giovedì"
            : "lunedì"}
        </p>
      </Modal>
    </>
  );
};

Click.propTypes = {};

export default Click;
