import React from "react";
import styled from "styled-components";
import { Button } from "../../components/Ui";
import Parser from "html-react-parser";
import { respondTo } from "../../theme/mixin";
import Fade from "../../components/Fade/Fade";
import { ChevronLeft } from "@styled-icons/boxicons-regular";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";

const SPContainer = styled.div`
  margin-top: 120px;

  .sp-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .sp-image {
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;
      background-color: #fff;
      position: relative;
      overflow: hidden;
      border-radius: 5px;
      box-shadow: 0px 5px 15px #00000029;

      ${respondTo.md`
        flex: 0 0 300px;
      `}

      & > img {
        width: 100%;
        height: auto;
        z-index: 0;
      }

      .sp-brand {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        overflow: hidden;
        z-index: 1;
        background-color: #fff;
        display: flex;
        align-items: center;
        box-shadow: 0px 5px 15px #00000029;

        img {
          width: 100%;
        }
      }
    }

    .sp-info {
      width: 100%;
      margin-top: 30px;

      ${respondTo.md`
        width: calc(100% - 350px);
        margin-top: 0;
      `}

      .sp-header {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        img {
          margin-right: 10px;
        }

        span {
          font-size: 14px;
          display: block;
          color: #e4e7f1;
          font-weight: 300;
          margin-bottom: 2px;
        }

        strong {
          color: #adb1bc;
        }
      }

      .sp-body {
        .sp-brand-name {
          color: #6cb967;
          font-size: 18px;
        }

        .sp-brand-subtitle {
          display: block;
          font-size: 28px;
          color: #185e14;
          margin-bottom: 10px;
        }

        .sp-shipment {
          display: flex;
          align-items: center;
          margin-top: 15px;

          img {
            margin-right: 5px;
          }

          span {
            font-size: 14px;
            color: #3f943a;
          }
        }
      }

      .sp-footer {
        display: flex;
        margin-top: 50px;
      }
    }
  }
`;

const ContestPrize = ({ prize, back }) => {
  return (
    <SPContainer>
      <Fade show={true}>
        {prize && (
          <div className="sp-wrapper">
            <div className="sp-image">
              <img src={prize.media.src} alt="" />
            </div>

            <div className="sp-info">
              <ContentBorderBox position="right" color="#3F943A">
                <div className="sp-header">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/icons/contest-product-icon.svg"
                    }
                    alt=""
                  />
                  <div>
                    <span>Codice prodotto</span>
                    <strong>
                      {prize["id_ns-form-label"]
                        ? prize["id_ns-form-label"]
                        : prize.code}
                    </strong>
                  </div>
                </div>

                <div className="sp-body">
                  <span className="sp-brand-name">{prize.brand}</span>
                  <span className="sp-brand-subtitle">
                    {prize.internal_title}
                  </span>
                  {Parser(prize.description)}
                </div>

                <div className="sp-footer">
                  <Button
                    onClick={back}
                    upper
                    gradient={{ left: "#E4E7F1", right: "#ADB1BC" }}
                    hoverGradient={{ left: "#FF7DB9", right: "#E61274" }}
                  >
                    <ChevronLeft size={30} />
                    Indietro
                  </Button>
                </div>
              </ContentBorderBox>
            </div>
          </div>
        )}
      </Fade>
    </SPContainer>
  );
};

export default ContestPrize;
