import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Loader } from "../../components/Ui";
import { fakeAuthInit } from "../../store/actions";

const FakeLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const loadingFirstAccess = useSelector(
    (state) => state.auth.loadingFirstAccess
  );
  const errorFirstAccess = useSelector((state) => state.auth.errorFirstAccess);

  useEffect(() => {
    if (!loadingFirstAccess) {
      history.push("/");
    }
  }, [history, loadingFirstAccess, errorFirstAccess]);

  useEffect(() => {
    if (location && location.pathname) {
      const token = location.pathname.replace("/impersonate/", "");
      if (token) {
        dispatch(fakeAuthInit(token));
      }
    }
  }, [dispatch, location]);

  return <Loader initial show={loadingFirstAccess} />;
};

export default FakeLogin;
