import * as actionTypes from "./actionTypes";
import customaxios from "../../config/axios-refresh-token";

const getCmsFooterStart = () => {
  return {
    type: actionTypes.GET_CMS_FOOTER_START,
  };
};

const getCmsFooterSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_FOOTER_SUCCESS,
    content,
  };
};

const getCmsFooterError = (error) => {
  return {
    type: actionTypes.GET_CMS_FOOTER_ERROR,
    error,
  };
};

export const getCmsFooter = () => {
  let endpoint = `cms/footer`;
  return (dispatch) => {
    dispatch(getCmsFooterStart());
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsFooterSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          dispatch(getCmsFooterError(error));
          reject(error);
        });
    });
  };
};

export const getCmsHow = () => {
  let endpoint = `cms/promo/get/howItWorks`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsHowSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsHowSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_HOW_SUCCESS,
    content,
  };
};

export const getCmsCoupon = () => {
  let endpoint = `cms/promo/get/coupon`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsCouponSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsCouponSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_COUPON_SUCCESS,
    content,
  };
};

export const getCmsConvalida = () => {
  let endpoint = `cms/convalidaScontrino`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsConvalidaSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsConvalidaSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_CONVALIDA_SUCCESS,
    content,
  };
};
export const getCmsHome = () => {
  let endpoint = `cms/home`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsHomeSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsHomeSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_HOME_SUCCESS,
    content,
  };
};
export const getCmsComeFunziona = () => {
  let endpoint = `cms/comeFunziona`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsComeFunzionaSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsComeFunzionaSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_COME_FUNZIONA_SUCCESS,
    content,
  };
};
export const getCmsAreaPartner = () => {
  let endpoint = `cms/area-partner`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsAreaPartnerSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsAreaPartnerSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_AREA_PARTNER_SUCCESS,
    content,
  };
};
export const getCmsCatalogo = () => {
  let endpoint = `cms/catalogo`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsCatalogoSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsCatalogoSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_CATALOGO_SUCCESS,
    content,
  };
};
export const getCmsVinciancoradipiu = () => {
  let endpoint = `cms/vinciancoradipiu`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsVinciancoradipiuSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsVinciancoradipiuSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_VINCI_ANCORA_DI_PIU_SUCCESS,
    content,
  };
};
export const getCmsPromoSurvey = () => {
  let endpoint = `cms/promo-survey`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsPromoSurveySuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsPromoSurveySuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_PROMO_SURVEY_SUCCESS,
    content,
  };
};
export const getCmsFaq = () => {
  let endpoint = `cms/FAQ`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsFaqSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsFaqSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_FAQ_SUCCESS,
    content,
  };
};
export const getCmsContatti = () => {
  let endpoint = `cms/contatti`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsContattiSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsContattiSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_CONTATTI_SUCCESS,
    content,
  };
};
