import React, { Suspense, useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { SideBar, Modal, Loader, Button, Text } from "../../components/Ui";
import SessionExpired from "../../components/SessionExpired";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import OrderSteps from "./OrderSteps";
import moment from "moment";
import { checkIfPastTargetDate } from "../../utils/utils";
import {
  closeOrderModal,
  clearOrder,
  closeWishlistModalAccess,
} from "../../store/actions/products";
import { acceptConsent, clearFirstAccessError } from "../../store/actions/auth";
import { fontWeight } from "styled-system";

const MainContent = styled.div`
  position: relative;
  flex: 1 0 auto;
  padding-bottom: 120px;
`;

const BaseLayout = (props) => {
  const {
    cmp: C,
    logo,
    toggleSidebar,
    showSidebar,
    isLogged,
    logout,
    bootApp,
    langs,
    oauths,
    ownLogin,
    multipromo,
    oauthInfo,
    isExpired,
    userInfo,
    oauth_profile_url,
    footercontent,
    isGame,
    ShoPrizeInHome,
    newOrder,
    firstAccess,
    orderModal,
    wishlistModalAccess,
  } = props;

  const dispatch = useDispatch();

  const closeOrderModalHandler = () => {
    dispatch(closeOrderModal());
  };

  const errorFirstAccess = useSelector((state) => state.auth.errorFirstAccess);

  const firstAccessComplete = useSelector(
    (state) => state.user.firstAccessComplete
  );
  const consents = useSelector((state) => state.user?.user?.consents);
  const acceptNewRegulationConcorso24 = consents?.some(
    (ele) => ele.consent_name === "accept_launch_contest_rules_24"
  );
  const acceptNewRegulationNovember24 = consents?.some(
    (ele) => ele.consent_name === "accept_new_regulation_november_24"
  );
  const [gameDate, setGameDate] = useState();
  const [accessErrorModal, setAccessErrorModal] = useState(false);
  const [isAccepteNewRegulation, setisAccepteNewRegulation] = useState(false);
  const [isAccepteNewRegulationLoading, setisAccepteNewRegulationLoading] =
    useState(false);
  console.log({ acceptNewRegulationNovember24 });
  useEffect(() => {
    if (!acceptNewRegulationNovember24 && consents?.length > 0) {
      setisAccepteNewRegulation(true);
    }
  }, [acceptNewRegulationNovember24]);

  useEffect(() => {
    if (errorFirstAccess) {
      setAccessErrorModal(errorFirstAccess);
    }
  }, [errorFirstAccess]);

  const gameObj = useSelector((store) => store.promo.game[0]);
  useEffect(() => {
    if (gameObj && gameObj.interaction) {
      setGameDate(JSON.parse(gameObj.interaction.data));
    }
  }, [gameObj]);

  useEffect(() => {
    if (
      (!checkIfPastTargetDate("2024-07-22T00:00:00") &&
        userInfo &&
        !firstAccessComplete &&
        !userInfo?.accept_launch_contest_rules_23 &&
        !userInfo?.first_access_done) ||
      (checkIfPastTargetDate("2024-07-22T00:00:00") &&
        userInfo &&
        !firstAccessComplete &&
        !acceptNewRegulationConcorso24 &&
        !userInfo?.first_access_done)
    ) {
      props.history.push("/first-access");
    } else if (
      (!checkIfPastTargetDate("2024-07-22T00:00:00") &&
        firstAccess !== undefined &&
        firstAccess &&
        !userInfo?.accept_launch_contest_rules_23) ||
      (checkIfPastTargetDate("2024-07-22T00:00:00") &&
        firstAccess !== undefined &&
        firstAccess &&
        !acceptNewRegulationConcorso24)
    ) {
      props.history.push("/rush-and-win");
    }
  }, [userInfo, firstAccessComplete]);

  let lazy = null;
  const accepteRegulationNovember24Handler = async () => {
    setisAccepteNewRegulationLoading(true);
    await dispatch(acceptConsent("accept_new_regulation_november_24"));
    setisAccepteNewRegulationLoading(false);
    setisAccepteNewRegulation(false);
  };
  const Content = (
    <>
      <Header
        isLogged={isLogged}
        logo={logo}
        toggleSidebar={toggleSidebar}
        showSidebar={showSidebar}
        logout={logout}
        bootApp={bootApp}
        langs={langs}
        oauths={oauths}
        ownLogin={ownLogin}
        multipromo={multipromo}
        oauthInfo={oauthInfo}
        userInfo={userInfo}
        oauth_profile_url={oauth_profile_url}
      />

      <MainContent isGame={isGame}>
        <div className="container">
          {}
          <SideBar
            isLogged={isLogged}
            show={showSidebar}
            logout={logout}
            toggleSidebar={toggleSidebar}
            oauths={oauths}
            ownLogin={ownLogin}
            oauthInfo={oauthInfo}
            bootApp={bootApp}
            langs={langs}
            oauth_profile_url={oauth_profile_url}
          />
          <main>
            <C isLogged={isLogged} />
          </main>
        </div>
      </MainContent>

      <Footer footercontent={footercontent} />
      {/* <Modal
        show={orderModal.isOpen}
        close={() => {
          if (newOrder.completed) {
            dispatch(clearOrder());
          }
          closeOrderModalHandler();
        }}
      >
        {isLogged ? (
          <>
            {newOrder.completed ? (
              <>
                Il tuo premio è stato richiesto correttamente, riceverai
                un'email con il riepilogo dell'ordine.
              </>
            ) : (
              <>
                {orderModal.product && (
                  <>
                    {userInfo.score >= orderModal.product.points ? (
                      <OrderSteps
                        product={orderModal.product}
                        newOrder={newOrder}
                      />
                    ) : (
                      "Non hai abbastanza punti per questo premio."
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            Devi eseguire l'accesso prima di poter ritirare un premio.
            <Link
              to={{
                pathname: "https://mylinkempage.linkem.com/User/Login",
              }}
              target="_blank"
            >
              <Button
                upper
                gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
                style={{ marginTop: 20 }}
              >
                Accedi
                <ChevronRight size={30} />
              </Button>
            </Link>
          </>
        )}
      </Modal> */}

      <Modal
        show={wishlistModalAccess}
        close={() => dispatch(closeWishlistModalAccess())}
      >
        Devi eseguire l'accesso prima di inserire prodotti in wishlist.
        <Link
          to={{
            pathname: "https://mylinkempage.linkem.com/MyLinkPlus",
          }}
          target="_blank"
        >
          <Button
            upper
            gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
            style={{ marginTop: 20 }}
          >
            Accedi
            <ChevronRight size={30} />
          </Button>
        </Link>
      </Modal>

      <Modal
        show={accessErrorModal}
        close={() => {
          setAccessErrorModal(false);
          dispatch(clearFirstAccessError());
        }}
      >
        {accessErrorModal}
      </Modal>
      <Modal
        show={isAccepteNewRegulation}
        close={async () => {
          setisAccepteNewRegulation(false);
          await accepteRegulationNovember24Handler();
        }}
      >
        <Text>
          Gentile cliente, la informiamo che abbiamo apportato una modifica al
          Regolamento. Nella giornata di lunedì 04.11.2024, chi parteciperà al
          concorso Vinci sempre di più accumulerà il doppio dei punti (5+5) per
          ciascuna giocata effettuata nell’Instant Win. Per visionare il
          regolamento completo{" "}
          <Link
            to={
              process.env.PUBLIC_URL +
              "/pdf/MODIFICA_Reg_OP_MyLinkPlus_2021_2022_2023_2024_2025.pdf"
            }
            target="_blank"
            style={{
              color: "#f00",
              textDecoration: "underline",
              fontWeight: "600",
              whiteSpace: "nowrap",
            }}
          >
            clicca qui.
          </Link>{" "}
          <Button
            gradient={{ left: "#e61274", right: "#e61274" }}
            onClick={accepteRegulationNovember24Handler}
            style={{ margin: "20px auto 0", justifyContent: "center" }}
            disabled={isAccepteNewRegulationLoading}
          >
            Ok
          </Button>
        </Text>
      </Modal>
    </>
  );

  let Comp = lazy ? (
    <Suspense fallback={<Loader show={true} />}>{Content}</Suspense>
  ) : (
    <React.Fragment>{Content}</React.Fragment>
  );

  return <React.Fragment>{Comp}</React.Fragment>;
};

BaseLayout.defaultProps = {
  type: "base",
};

const mapStateToProps = (state) => {
  return {
    newOrder: state.product.newOrder,
    orderModal: state.product.orderModal,
    wishlistModalAccess: state.products.wishlistModalAccess,
    firstAccess: state.user.user.first_access_done,
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    langs: state.app.config.langs,
    showSidebar: state.ui.showSidebar,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
    oauthInfo: state.app.oauthInfo,
    isExpired: state.error.sessionExpired,
    error404: state.promo.error404,
    codes: state.user.user.codes,
    userInfo: state.user.user.userinfo,
    oauth_profile_url: state.user.user.oauth_profile_url,
    footercontent: state.cms.footer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bootApp: () => dispatch(actionCreators.bootApp()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
    logout: () => dispatch(actionCreators.logout()),
    getUserInfo: () => dispatch(actionCreators.getUserInfo()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
