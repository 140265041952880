import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Section, Loader, CustomContainer } from "../../components/Ui";
import Seo from "../../components/Seo";
import Games from "../../components/Games";
import { getSlug } from "../../utils/utils";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions";
import useSpring from "../../hooks/useSpring";
import NotFound from "../NotFound";

const Game = (props) => {
  const {
    appConfig: {
      settings: { multipromo },
    },
    userWin,
    lang,
    isLogged,
    getGame,
    game,
    gameRetry,
    setGameRetry,
    sendGameResult,
    loadingSendGameResult,
    errorSendGameResult,
    loadingGetCodeAfterResult,
    // loadinggame,
    getCodeAfterGame,
    loadingGetCodeAfterGame,
    errorGetCodeAfterGame,
    match: {
      params: { id },
    },
  } = props;
  const loadinggame = useSelector((state) => state.promo?.loadinggame);

  let sendGameObj = {
    getGame,
    gameRetry,
    setGameRetry,
    sendGameResult,
    loadingSendGameResult,
    loadingGetCodeAfterResult,
    errorSendGameResult,
    getCodeAfterGame,
    loadingGetCodeAfterGame,
    errorGetCodeAfterGame,
  };
  const dispatch = useDispatch();
  let slug = "play";
  let seo = null;
  const carnevale = useSpring();
  const games = useSelector((state) => state.game?.games);
  const [error, setError] = useState(false);
  if (multipromo) {
    slug = getSlug();
  }

  if (game.length > 0) {
    const {
      i18l: {
        seo: { title, description, keywords },
      },
    } = game[0];
    seo = <Seo title={title} description={description} keywords={keywords} />;
  }
  useEffect(() => {
    dispatch(actionCreators.getGames(slug, isLogged));
  }, []);

  useEffect(() => {
    if (games.length > 0) {
      if (
        (new Date().getDay() !== +process.env.REACT_APP_MONDAY_DAY &&
          +id === process.env.REACT_APP_GAME_MONDAY) ||
        (new Date().getDay() !== +process.env.REACT_APP_THURSDAY_DAY &&
          +id === process.env.REACT_APP_GAME_THURSDAY) ||
        game?.error
      ) {
        setError(true);
      }
    }
  }, [games]);

  useEffect(() => {
    getGame(
      id,
      slug,
      props.history.location.state ? props.history.location.state.cid : ""
    );
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <CustomContainer>
      {loadinggame ? (
        <Loader show={loadinggame} full />
      ) : game?.error || error ? (
        <NotFound msg={game?.status || "Game not related to this promotion"} />
      ) : (
        <React.Fragment>
          {seo}
          <Games
            games={game}
            isLogged={isLogged}
            sendGameObj={sendGameObj}
            lang={lang}
          />
        </React.Fragment>
      )}
    </CustomContainer>
  );
};

Game.propTypes = {};

const mapStateToProps = (state) => {
  return {
    lang: state.app.config.lang,
    game: state.promo.game,
    // loadinggame: state.promo.loadinggame,
    loadingSendGameResult: state.game.loadingSendGameResult,
    errorSendGameResult: state.game.errorSendGameResult,
    loadingGetCodeAfterGame: state.game.loadingGetCodeAfterGame,
    errorGetCodeAfterGame: state.game.errorGetCodeAfterGame,
    loadingGetCodeAfterResult: state.game.loadingGetCodeAfterResult,
    gameRetry: state.promo.gameRetry,
    appConfig: state.app.config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGame: (id, slug, cid) => dispatch(actionCreators.getGame(id, slug, cid)),
    sendGameResult: (id, result) =>
      dispatch(actionCreators.sendGameResult(id, result)),
    getCodeAfterGame: (id, result, gc) =>
      dispatch(actionCreators.getCodeAfterGame(id, result, gc)),
    setGameRetry: () => dispatch(actionCreators.setGameRetry()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Game));
