import React, { useState } from "react";
import { Input, Button, CustomContainer, Text } from "../../components/Ui";
import { contactForm } from "../../formsConfig/formsConfig";
import styled from "styled-components";
import useForm from "../../hooks/useForm";
import { respondTo } from "../../theme/mixin";
import customaxios from "../../config/axios-refresh-token";
import { Modal } from "../../components/Ui";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { useSelector } from "react-redux";

const ContactWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 270px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }

  .contact-form-wrapper {
    width: 100%;
    padding: 0 15px;

    ${respondTo.md`
      width: 80%;
      margin: 0 10%;
      padding: 0;
    `}

    .contactContent-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const Contact = () => {
  const [modal, setModal] = useState(false);
  const { title, content } = useSelector(
    (state) => state.cms.contatti?.[0]?.contents?.[0].i18l || {}
  );
  const submitHandler = () => {
    customaxios
      .post(
        "/app/contactus",
        { topic: formData.topic.value, message: formData.message.value },
        {
          headers: {
            "X-Custom-Header": { alias: "insiemepervincere.com", lang: "it" },
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setModal(true);
          resetFormHandler();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    resetFormHandler,
  } = useForm(submitHandler, contactForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        resetFormHandler={resetFormHandler}
      />
    );
  });

  return (
    <>
      <ContactWrapper>
        <div className="slide">
          <img
            src={process.env.PUBLIC_URL + "/images/contatti-slide.jpg"}
            alt=""
          />

          <div className="content">
            <div>
              <Text as="h3">{title}</Text>
              <Text>{content}</Text>
            </div>
          </div>
        </div>

        <div className="contact-form-wrapper">
          <ContentBorderBox
            title="Compila il form"
            color="#EB5A3F"
            style={{ marginTop: 50 }}
          >
            <div className="contactContent">
              <form onSubmit={formSubmitHandler}>
                {inputs}
                <div className="contactContent-footer">
                  <Button
                    upper
                    gradient={{ right: "#EB5A3F", left: "#FDCA46" }}
                  >
                    Invia
                    <ChevronRight size={30} />
                  </Button>
                </div>
              </form>
            </div>
          </ContentBorderBox>
        </div>
      </ContactWrapper>
      <Modal show={modal} close={() => setModal(false)}>
        <p>Grazie, il tuo messaggio è stato inviato con successo.</p>
      </Modal>
    </>
  );
};

export default Contact;
