import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { css } from "styled-components";
import { darken } from "polished";

const ImagePreviewContainer = styled.div`
  width: 100%;
  margin: 8px auto;
  background-color: #e4eaef;
  border-radius: 8px;
  position: relative;
  display: ${(props) => (props.show ? "inline-block" : "none")};
  img {
    object-fit: cover;
  }
`;

const ImagePreview = styled.div`
  max-width: 300px;
  margin: 8px auto;
  display: flex;
  justify-content: center;
`;

const DeleteContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 16px;
  left: 16px;
`;

const UploadButton = styled.div`
  display: ${(props) => (props.show ? "inline-block" : "none")};
  border-radius: ${(props) =>
    props.theme.btnRound ? "1.5625rem" : "0"}; /* 25px */
  border: 1px solid
    ${(props) =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : "transparent"};
  text-align: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  outline: none;
  color: ${(props) => props.theme.btnTextColor};
  padding: 0 1.5625rem; /* 25px */
  min-width: ${(props) =>
    props.small ? "10rem" : "10.625rem"}; /* 120px */ /* 170px */
  min-width: ${(props) =>
    props.fullwidth ? "100%" : ""}; /* 120px */ /* 170px */
  background-color: ${(props) =>
    props.bgcolor
      ? props.bgcolor
      : props.secondary
      ? props.theme.secondary
      : props.theme.primary};
  background-image: ${(props) => `url(${props.bgimage})`};
  background-repeat: no-repeat;
  background-position: 22px center;
  transition: all 0.3s;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-transform: ${(props) => (props.upper ? "uppercase" : "none")};
  opacity: ${(props) => (props.disabled ? ".5" : "1")};
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${(props) =>
          props.bgcolor
            ? darken(0.1, props.bgcolor)
            : props.secondary
            ? darken(0.1, props.theme.secondary)
            : darken(0.1, props.theme.primary)};
      }
    `}
`;

const File = (props) => {
  const { imagePreviewUrl, deleteFileHandler } = props;
  const {
    onSetImmagine,
    onSetFormValid,
    onSetSelectedFiles,
    resetImages,
    resetImage,
    name,
  } = props;

  const upRef = useRef(null);

  const [imagePreviewUrl2, setImgPreviewUrl2] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const fileUploadHandler2 = (event) => {
    event.preventDefault();

    const file = event.target.files[0];

    if (file !== undefined) {
      //setSelectedFile(file);

      setSelectedFile({
        name: event.target.name,
        file: event.target.files[0],
      });

      onSetFormValid(event);

      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.type !== "application/pdf") {
          setImgPreviewUrl2(reader.result);
          onSetImmagine(true);
        } else {
          //setImgPreviewUrl(reader.result);
          //immagine di placeholder
        }
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    } else {
      setImgPreviewUrl2(null);
    }
  };

  const deleteFileHandler2 = () => {
    setImgPreviewUrl2(null);
    onSetImmagine(false);
    resetImages(name);
    setSelectedFile(null);
  };

  useEffect(() => {
    if (selectedFile) {
      onSetSelectedFiles(selectedFile);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (resetImage) {
      //setImgPreviewUrl2(null);
    }
  }, [resetImage]);

  return (
    <>
      <ImagePreviewContainer show={imagePreviewUrl2}>
        <ImagePreview>
          {imagePreviewUrl2 && <img src={imagePreviewUrl2} alt="" />}
        </ImagePreview>
        <DeleteContainer onClick={deleteFileHandler2}>
          <img
            src={process.env.PUBLIC_URL + "/icons/delete-scontrino.svg"}
            alt=""
          />
        </DeleteContainer>
      </ImagePreviewContainer>

      <input
        style={{ display: "none" }}
        ref={upRef}
        type="file"
        accept="image/*,application/pdf"
        name={props.elementConfig.name}
        onChange={fileUploadHandler2}
      />
      <UploadButton
        secondary
        upper
        fullwidth
        {...props}
        onClick={() => upRef.current.click()}
        show={!imagePreviewUrl2}
      >
        {props.elementConfig.placeholder}
      </UploadButton>
    </>
  );
};

export default File;
