import React, { Suspense, useEffect, useState, useSelector } from "react";
import { Loader } from "../../components/Ui";
import SessionExpired from "../../components/SessionExpired";
import styled from "styled-components";
import { connect } from "react-redux";

const AuthContainer = styled.div`
  position: relative;
  /* min-width: 100vw;
	min-height: 100vh;
	background-color: ${(props) => props.theme.overlay};
	display: flex;
	justify-content: center;
	align-items: center; */
`;

const AuthLayout = (props) => {
  const { userInfo, firstAccessComplete } = props;
  const C = props.cmp;
  const lazy = props.lazy;
  const isExpired = props.isExpired;

  let Comp = null;

  if (lazy) {
    Comp = (
      <Suspense fallback={<Loader show={true} />}>
        <C {...props} />
      </Suspense>
    );
  } else {
    Comp = <C {...props} />;
  }

  return <AuthContainer>{Comp}</AuthContainer>;
};

const mapStateToProps = (state) => {
  return {
    logo: state.app.config.logo,
    isExpired: state.error.sessionExpired,
  };
};

export default connect(mapStateToProps)(AuthLayout);
