import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { respondTo } from "../../theme/mixin";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";

const SurveysListWrapper = styled.div`
  .surveys-list {
    background-color: #00abe5;
    box-shadow: 0px 5px 15px #00000026;
    border-radius: 15px;
    padding: 15px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0 20px;
    .slick-track {
      margin-left: 0;
    }

    & > strong {
      color: #fff;
      font-size: 21px;
    }

    .surveys-list-wrapper {
      //display: flex;
      margin: 20px -20px;

      & .slick-slide > div {
        &:hover {
          opacity: 0.5;
        }
      }

      .survey-no-link {
        cursor: default;
        pointer-events: none;
      }

      .survey-box {
        padding: 0 20px;
        opacity: 1;

        &.completed {
          opacity: 0.4;
        }

        img {
          width: 100%;
        }

        .survey-title {
          display: block;
          text-align: center;
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0px 3px 15px #00000026;
          padding: 5px 10px 3px 10px;
          text-transform: uppercase;
          color: #00abe5;

          .progress-bar {
            width: 100%;
            height: 5px;
            background-color: #e4e7f1;
            border-radius: 50px;
            margin-top: 5px;
            margin-bottom: 2px;
            position: relative;
            overflow: hidden;

            &.in-play {
              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 40%;
                height: 5px;
                background: rgb(116, 219, 254);
                background: linear-gradient(
                  5deg,
                  rgba(116, 219, 254, 1) 0%,
                  rgba(9, 175, 231, 1) 100%
                );
                border-radius: 50px;
              }
            }
          }

          span {
            text-transform: capitalize;
            display: block;
            font-size: 10px;
            text-align: center;
            font-weight: 300;
          }

          &.coming {
            opacity: 0.4;
            background-color: rgba(255, 255, 255, 0.4);
          }
        }

        .survey-status {
          text-align: center;
          color: #fff;
          opacity: 0.5;
          margin-top: 10px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.completed {
            opacity: 1;
          }
        }
      }
    }
  }
`;

const SurveysList = () => {
  const { id } = useParams();
  const games = useSelector((state) => state.game.games);

  const options = {
    dots: false,
    autoplay: false,
    slidesToShow: 5,
    swipeToSlide: true,
    centerMode: true,
    arrows: false,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <SurveysListWrapper>
      <div className="surveys-list">
        <strong>I tuoi sondaggi /Quiz</strong>
        <div className="surveys-list-wrapper">
          <Slider {...options}>
            {games &&
              [...games].reverse().map((game) => (
                <Link
                  key={game.id}
                  to={
                    game.log.playslog
                      ? "#"
                      : `/sondaggi/promo-survey/game/${game.id}`
                  }
                  className={`${game.log.playslog ? "survey-no-link" : null}`}
                >
                  <div
                    className={`survey-box ${
                      game.log.playslog ? "completed" : null
                    }`}
                  >
                    {game.main_image && <img src={game.main_image} alt="" />}

                    <strong className="survey-title">
                      {game.i18l.info.title}
                      {!game.log.playslog ? (
                        <div
                          className={`progress-bar ${
                            Number(id) === game.id ? "in-play" : null
                          }`}
                        ></div>
                      ) : (
                        <span>+ 10 Pt</span>
                      )}
                    </strong>

                    <strong
                      className={`survey-status ${
                        game.log.playslog ? "completed" : null
                      }`}
                    >
                      {game.log.playslog ? (
                        "Completato"
                      ) : Number(id) === game.id ? (
                        <>
                          In corso <ChevronRight size={22} />
                        </>
                      ) : (
                        <>
                          Inizia <ChevronRight size={22} />
                        </>
                      )}
                    </strong>
                  </div>
                </Link>
              ))}

            <Link to="#" className="survey-no-link">
              <div className="survey-box">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/badge-upcoming-cut.png"
                  }
                  alt=""
                />

                <strong className="survey-title coming">
                  PRESTO <br />
                  IN ARRIVO
                  <span>+ 10 Pt</span>
                </strong>
              </div>
            </Link>
            <Link to="#" className="survey-no-link">
              <div className="survey-box">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/badge-upcoming-cut.png"
                  }
                  alt=""
                />

                <strong className="survey-title coming">
                  PRESTO <br />
                  IN ARRIVO
                  <span>+ 10 Pt</span>
                </strong>
              </div>
            </Link>
            <Link to="#" className="survey-no-link">
              <div className="survey-box">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/badge-upcoming-cut.png"
                  }
                  alt=""
                />

                <strong className="survey-title coming">
                  PRESTO <br />
                  IN ARRIVO
                  <span>+ 10 Pt</span>
                </strong>
              </div>
            </Link>
            <Link to="#" className="survey-no-link">
              <div className="survey-box">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/badge-upcoming-cut.png"
                  }
                  alt=""
                />

                <strong className="survey-title coming">
                  PRESTO <br />
                  IN ARRIVO
                  <span>+ 10 Pt</span>
                </strong>
              </div>
            </Link>
            <Link to="#" className="survey-no-link">
              <div className="survey-box">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/badge-upcoming-cut.png"
                  }
                  alt=""
                />

                <strong className="survey-title coming">
                  PRESTO <br />
                  IN ARRIVO
                  <span>+ 10 Pt</span>
                </strong>
              </div>
            </Link>
          </Slider>
        </div>
      </div>
    </SurveysListWrapper>
  );
};

export default SurveysList;
