import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useForm from "../../hooks/useForm";
import {
  firstAccessForm,
  firstAccessFormPast,
  newFirstAccessForm,
} from "../../formsConfig/formsConfig";
import { Input, Button, Loader } from "../../components/Ui";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import { respondTo } from "../../theme/mixin";
import {
  activateUser,
  clearActivateUser,
  acceptConsent,
} from "../../store/actions/auth";
import moment from "moment";
import { checkIfPastTargetDate } from "../../utils/utils";
const FirstAccess = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userInfo = useSelector((state) => state.user.user.userinfo);
  const firstAccessEnd = useSelector((state) => state.user.firstAccessEnd);
  const loading = useSelector((state) => state.user.firstAccessLoading);

  const activateUserHanlder = (data) => {
    if (!checkIfPastTargetDate("2024-07-22T00:00:00")) {
      dispatch(activateUser(data.accept_launch_contest_rules_23));
    } else {
      dispatch(activateUser(1, data));
    }
  };

  useEffect(() => {
    if (firstAccessEnd) {
      dispatch(clearActivateUser());
      history.push("/");
    }
  }, [firstAccessEnd]);
  const consents = useSelector((state) => state.user?.user?.consents);
  const acceptNewRegulationConcorso24 = consents?.some(
    (ele) => ele.consent_name === "accept_launch_contest_rules_24"
  );

  useEffect(() => {
    if (
      (!checkIfPastTargetDate("2024-07-22T00:00:00") &&
        userInfo &&
        userInfo.accept_launch_contest_rules_23 &&
        userInfo.first_access_done) ||
      (checkIfPastTargetDate("2024-07-22T00:00:00") &&
        userInfo &&
        acceptNewRegulationConcorso24 &&
        userInfo.first_access_done) ||
      (checkIfPastTargetDate("2024-07-22T00:00:00") &&
        userInfo &&
        !acceptNewRegulationConcorso24 &&
        userInfo.accept_launch_contest_rules_23 &&
        userInfo.first_access_done)
    ) {
      props.history.push("/");
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.first_access_done) {
      formData.privacy.valid = true;
      formData.rules.valid = true;
    }
  }, [userInfo]);

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    datePickerHandler,
    resetFormHandler,
  } = useForm(
    activateUserHanlder,
    checkIfPastTargetDate("2024-07-22T00:00:00")
      ? firstAccessForm()
      : firstAccessFormPast()
  );

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        changedDatePicker={datePickerHandler}
        resetFormHandler={resetFormHandler}
        {...inp}
      />
    );
  });

  return (
    <div className="container">
      <Wrapper>
        <ContentBorderBox color="#EB5A3F" rushAndWin={true}>
          <div className="sides-wrapper">
            <div className="bg-image">
              {checkIfPastTargetDate("2024-07-22T00:00:00") ? (
                <img
                  src={
                    checkIfPastTargetDate("2024-07-22T00:00:00")
                      ? process.env.PUBLIC_URL +
                        "/images/rushandwinregisterednew.png"
                      : process.env.PUBLIC_URL +
                        "/images/rushandwinregistered.png"
                  }
                  alt=""
                />
              ) : (
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/rushandwinregistered.png"
                  }
                  alt=""
                />
              )}
            </div>
            <div className="left-side">
              <div className="access-header">
                <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
              </div>
              <h3>
                Ciao, <strong>{userInfo ? userInfo.firstname : null}</strong>!
              </h3>

              <strong>
                Iscriviti subito a MyLink Plus, il programma fedeltà dedicato ai
                clienti Linkem, e festeggia con noi l’inizio di un nuovo anno
                insieme.
              </strong>
              <p>
                {checkIfPastTargetDate("2024-07-22T00:00:00") ? (
                  <>
                    <strong>
                      Con MyLinkPlus hai + contenuti + premi e molte +
                      possibilità di vincere!
                    </strong>{" "}
                    <strong>Dal 22 luglio al 25 agosto 2024</strong> potrai
                    partecipare alla Fase 1 del nostro nuovo concorso a premi
                    Vinci sempre di più. <br />
                    Tutti coloro che si iscriveranno al concorso parteciperanno
                    all’estrazione finale di{" "}
                    <strong>
                      12 fotocamere digitali istantanea KODAK Smile+
                    </strong>{" "}
                    e{" "}
                    <strong>
                      {" "}
                      1 voucher viaggio Boscolo del valore di 5.000€!
                    </strong>
                    <br />
                    <br />
                    In più, in palio per i più veloci,{" "}
                    <strong>
                      500 voucher per la sottoscrizione di un abbonamento
                      annuale ad una delle riviste digitali Grandi Clienti
                      Mondadori.
                    </strong>
                    <br />
                    <br />E non è finita qui!{" "}
                    <strong>
                      Dal 2 settembre al 30 giugno 2025 potrai giocare tutti i
                      lunedì
                    </strong>{" "}
                    e provare ad aggiudicarti uno dei{" "}
                    <strong>fantastici premi</strong> in palio.
                  </>
                ) : (
                  <>
                    <strong>
                      Con MyLinkPlus hai + contenuti + premi e molte +
                      possibilità di vincere!
                    </strong>
                    <br />
                    <br />
                    <strong>Dal 17 al 31 luglio</strong> potrai partecipare al
                    nostro nuovo concorso a premi.{" "}
                    <strong>
                      In palio, per i più veloci, 500 voucher per scaricare una
                      guida Lonely Planet.
                    </strong>{" "}
                    <br />
                    <br />
                    Inoltre, tutti coloro che si iscriveranno entro il 31 luglio
                    parteciperanno all’estrazione finale di{" "}
                    <strong>12 droni per selfie Air Selfie</strong> e un{" "}
                    <strong>
                      1 voucher Alpitour World del valore di 5.000 €
                    </strong>
                    !
                    <br />
                    <br />E non è finita qui!{" "}
                    <strong>
                      Dal 4 settembre potrai giocare tutti i lunedì e i giovedì
                    </strong>{" "}
                    e provare ad aggiudicarti uno dei{" "}
                    <strong>fantastici premi a sorpresa</strong> in palio.
                  </>
                )}
              </p>
            </div>

            <div className="right-side">
              <h4>Consensi</h4>
              <p>
                <strong>Per completare l'iscrizione </strong> e aderire al
                programma fedeltà{" "}
                <strong>
                  MYLINKPLUS 2021/2022/2023/2024
                  {checkIfPastTargetDate("2024-07-22T00:00:00") ? "/2025" : " "}
                </strong>
                , dichiaro di aver <strong>letto</strong> e{" "}
                <strong>accettato</strong>:
              </p>
              <div className="single-wrapper">
                <div className="single-consens revert-checkbox-order">
                  {inputs[0]}
                </div>
                <div className="single-consens revert-checkbox-order">
                  {inputs[1]}
                </div>
                <div className="single-consens revert-checkbox-order">
                  {inputs[2]}
                </div>
              </div>
              <form onSubmit={formSubmitHandler}>
                <Button
                  center
                  fullwidth
                  gradient={{ left: "#eb5c3f", right: "#FCC546" }}
                  upper
                  disabled={loading}
                >
                  {loading ? (
                    <Loader show={loading} color="#fff" inBtn />
                  ) : (
                    <>
                      Iscriviti
                      <ChevronRight size={30} />
                    </>
                  )}
                </Button>
                <small>* campi obbligatori.</small>
              </form>
            </div>
          </div>
        </ContentBorderBox>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  margin-top: 30px;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;

  ${respondTo.sm`
    padding: 0;
    margin-top: 80px;
  `}

  .bg-image {
    position: absolute;
    left: 50%;
    transform: translatex(-40%);
    bottom: -22px;
    height: auto;
    width: auto;
    display: none;

    img {
      width: 100%;
      height: 100%;
    }

    ${respondTo.sm`
      display: block;
    `};
  }

  .single-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 50px;

    .single-consens {
      width: 100%;
      box-shadow: 0px 5px 15px #00000026;
      background-color: #fff;
      border-radius: 12px;
      margin-bottom: 15px;
      height: 44px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      > div {
        margin-bottom: 0;
        width: 100%;
      }
      &.revert-checkbox-order {
        label {
          justify-content: space-between;
          &:before {
            order: 1;
            margin: 0;
          }
        }
      }
      &.d-none {
        display: none;
      }
      a {
        color: #eb5a3f;
        font-weight: 700;
        font-size: 12px;
        text-decoration: underline;
      }
    }
  }

  .sides-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    ${respondTo.sm`
      align-items: center;
      flex-wrap: nowrap;
    `};

    .left-side {
      width: 330px;

      h3 {
        font-size: 21px;
        font-weight: 700;
        margin: 15px 0;

        strong {
          color: #eb5a3f;
        }
      }

      & > strong {
        display: block;
        margin-bottom: 15px;
        color: #f2a55b;
      }

      p {
        font-size: 14px;
      }
    }

    .right-side {
      width: 355px;
      z-index: 1;

      h4 {
        font-size: 18px;
        color: #fff;
        margin-bottom: 15px;
        @media (max-width: 768px) {
          color: black;
          margin-top: 40px;
        }
      }

      p {
        font-size: 12px;
        margin-bottom: 25px;
      }

      button {
        justify-content: center;
      }

      small {
        color: #ffcb98;
        font-weight: 700;
        margin-top: 15px;
        font-size: 10px;
        display: block;
        text-align: center;
      }
    }
  }
`;

export default FirstAccess;
