import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import { respondTo } from "../../../theme/mixin";
import { Link } from "react-router-dom";
import { ChevronRight, ChevronLeft } from "@styled-icons/boxicons-regular";
import { Button } from "../../../components/Ui";
import ContentBorderBox from "../../../components/ContentBorderBox/ContentBorderBox";
import ProductBox from "../../../components/ProductBox/ProductBox";
import Avatar from "../../../components/Avatar/Avatar";

const NextArrow = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        left: 145,
        top: -27,
        color: "#ff7db9",
        cursor: "pointer",
      }}
    >
      <ChevronRight size={30} />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        left: 115,
        top: -27,
        color: "#ff7db9",
        cursor: "pointer",
      }}
    >
      <ChevronLeft size={30} />
    </div>
  );
};

const PremiatiWrapper = styled.div`
  .premiati-body {
    display: flex;
    flex-wrap: wrap;

    .premiati-slider {
      width: 100%;

      ${respondTo.md`
        width: 75%;
      `}

      .premiati-products-wrapper {
        display: flex;
        flex-wrap: wrap;

        .premiati-single-product {
          width: 100%;

          ${respondTo.md`
            width: 33.333%;
          `}
        }
      }
    }

    .premiati-wishlist {
      text-align: center;
      width: 100%;

      ${respondTo.md`
        width: 25%;
        border-left: 1px solid #e4e7f1;
      `}

      .premiati-no-wishlist {
        img {
          margin: 15px 0;
        }
      }

      .premiati-wishlist-user {
        display: inline-flex;
        align-items: center;
        margin: 15px auto 0 auto;

        .premiati-user-image {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          background-color: #cccccc;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 3px 6px #00000029;
        }

        .premiati-user-info {
          text-align: left;
          margin-left: 10px;

          span {
            display: block;
            font-weight: 300;
            font-size: 14px;
            text-transform: lowercase;

            &:first-letter {
              text-transform: uppercase;
            }
          }

          strong {
            color: #e61274;
          }
        }
      }
    }

    .premiati-box {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px;

      .box-image {
        width: 160px;
        height: 160px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0px 5px 15px #00000029;
      }

      .box-info {
        width: 100%;
        position: relative;
        top: -20px;
        text-align: center;

        .add-to-wishlist {
          background-color: #e61274;
          width: 50px;
          height: 50px;
          margin: 0 auto;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
        }

        p {
          color: #ff7db9;
        }

        strong {
          display: block;
        }

        .box-name {
          text-transform: uppercase;
        }

        .box-points {
          color: #ff7db9;
        }
      }
    }
  }

  .premiati-footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 25px;

    ${respondTo.md`
      justify-content: space-between;
    `}

    h4 {
      font-size: 28px;
      color: #e61274;
    }

    h4,
    p {
      display: none;

      ${respondTo.md`
        display: block;
      `}
    }
  }
`;

const Premiati = ({ products, wishlist, userInfo }) => {
  const options = {
    dots: false,
    autoplay: false,
    infinite: true,
    slidesToShow: 2,
    centerMode: true,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <ContentBorderBox
      title="Premiati di"
      subtitle="Scopri il nostro catalogo premi"
      icon={process.env.PUBLIC_URL + "/icons/premiati-icon.svg"}
      color="#e61274"
    >
      <PremiatiWrapper>
        <div className="premiati-body">
          <div className="premiati-slider">
            <span>In evidenza</span>
            <Slider {...options}>
              {products.map((prod, index) => (
                <ProductBox key={index} product={prod} paddingBottom />
              ))}
            </Slider>
          </div>
          <div className="premiati-wishlist">
            {wishlist && wishlist.length > 0 ? (
              <>
                <span>Dalla wishlist</span>
                <ProductBox product={wishlist[0]} />
              </>
            ) : (
              <>
                <span>
                  Aggiungi i premi
                  <br />
                  alla tua wishlist
                </span>
                <div className="premiati-no-wishlist">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/add-more-products.svg"
                    }
                    alt=""
                  />
                </div>
              </>
            )}

            {userInfo && (
              <div className="premiati-wishlist-user">
                <Avatar shadow />
                <div className="premiati-user-info">
                  <span>{userInfo.firstname}, hai</span>
                  <strong>{userInfo.score} Pt</strong>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="premiati-footer">
          <div>
            <h4 style={{ marginBottom: 10 }}>
              Scegli il tuo premio
              <br />e accumula punti per ottenerlo
            </h4>
            <p>
              Visita il nostro <strong>catalogo</strong> e inizia subito a
              comporre la tua <strong>wishlist</strong>!
            </p>
          </div>
          <Link to="/catalogo">
            <Button
              upper
              gradient={{ left: "#E61274", right: "#FF7DB9" }}
              hoverGradient={{ left: "#FF7DB9", right: "#FF7DB9" }}
            >
              Vai al catalogo <ChevronRight size={30} />
            </Button>
          </Link>
        </div>
      </PremiatiWrapper>
    </ContentBorderBox>
  );
};

export default Premiati;
