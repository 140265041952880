import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Loader } from "../../components/Ui";
import { authInit } from "../../store/actions";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isFirstAccess = useSelector(
    (state) => state.user.user.first_access_done
  );
  const loadingFirstAccess = useSelector(
    (state) => state.auth.loadingFirstAccess
  );
  const errorFirstAccess = useSelector((state) => state.auth.errorFirstAccess);

  useEffect(() => {
    if (!loadingFirstAccess) {
      if (isFirstAccess) {
        history.push("/first-access");
      } else if (errorFirstAccess) {
        history.push("/");
      } else {
        history.push("/");
      }
    }
  }, [history, isFirstAccess, loadingFirstAccess, errorFirstAccess]);

  useEffect(() => {
    if (location && location.pathname) {
      const token = location.pathname.replace("/login/", "");
      if (token) {
        dispatch(authInit(token));
      }
    }
  }, [dispatch, location]);

  return <Loader initial show={loadingFirstAccess} />;
};

export default Login;
