import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ChevronRight } from "@styled-icons/boxicons-regular";

const PartnerBox = ({ partner }) => {
  const ParnterWrapper = styled.div`
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: 0px 5px 15px #00000026;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(transparent, #3a3c42);
      z-index: 1;
    }

    &:hover:after {
      background: rgba(253, 202, 70, 0.8);
      transition: all 0.2s;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transition: all 0.2s;
    }

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    .partner-brand {
      display: flex;
      align-items: center;
      overflow: hidden;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: #fff;
      z-index: 10;
      box-shadow: 0px 5px 15px #00000029;
      margin-bottom: 15px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .partner-info {
      position: absolute;
      bottom: 10px;
      left: 10px;
      z-index: 10;
      color: #fff;

      strong {
        text-transform: uppercase;
      }

      .partner-offer {
        display: flex;
        align-items: center;

        span {
          display: block;
          margin-left: 5px;
          font-weight: 300;
          color: #fdca46;
        }
      }
    }
  `;

  return (
    <Link to={`/partner/${partner.id}`}>
      <ParnterWrapper>
        <div className="partner-brand">
          <img src={partner.logo} alt="" />
        </div>
        <img src={partner.main_image} alt="" />
        <div className="partner-info">
          <strong>{partner.name}</strong>
          <ChevronRight size={22} />

          <div className="partner-offer">
            <img
              src={process.env.PUBLIC_URL + "/icons/offer-icon.svg"}
              alt=""
            />
            <span>{partner.under_value}</span>
          </div>
        </div>
      </ParnterWrapper>
    </Link>
  );
};

export default PartnerBox;
