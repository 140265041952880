import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import { Button, Modal, Loader } from "../../components/Ui";
import { openOrderModal } from "../../store/actions/products";
import { ChevronRight, X } from "@styled-icons/boxicons-regular";
import { removeFromCart, addToWishlist } from "../../store/actions/wishlist";
import { orderProduct, clearOrder } from "../../store/actions/products";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import EditProfileStep from "../../layouts/BaseLayout/EditProfileStep";

const CartWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 270px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }

  .wish-header {
    display: flex;
    justify-content: space-between;

    span {
      color: #ff7db9;
      font-size: 18px;
      font-weight: 300;
    }
  }

  .wish-products {
    margin-top: 50px;

    .wish-product {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      border-radius: 15px;
      padding: 15px;
      flex-wrap: wrap;

      ${respondTo.md`
        flex-wrap: nowrap;
      `}

      &:nth-child(even) {
        background-color: #ecf0fa;
      }

      &:nth-child(1) {
        .prod-data:before,
        .prod-actions:before,
        .prod-points:before,
        .prod-percent:before {
          position: absolute;
          top: -15px;
          color: #ff7db9;
          font-weight: 300;
          font-size: 18px;
          display: none;

          ${respondTo.md`
            display: block;
          `}
        }

        .prod-data:before {
          content: "Premio";
        }
        .prod-percent:before {
          content: "Raggiunto";
          margin-left: -35px;
        }
        .prod-points:before {
          content: "Punti";
        }
        .prod-actions:before {
          content: "Azioni";
          margin-left: 45px;
        }
      }

      .prod-info {
        display: flex;
        align-items: center;
        max-width: 570px;

        .prod-image {
          display: flex;
          flex: 0 0 120px;
          height: 120px;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0px 5px 15px #00000029;
          overflow: hidden;
          align-items: center;

          ${respondTo.md`
            flex: 0 0 160px;
            height: 160px;
          `}

          img {
            width: 100%;
            height: auto;
          }
        }

        .prod-data {
          margin-left: 25px;

          ${respondTo.md`
            margin-left: 40px;
          `}

          span {
            color: #e61274;
          }

          a {
            display: block;
            text-transform: uppercase;
            margin-bottom: 10px;
            font-weight: 700;
            color: #3a3c42;
          }
        }
      }

      .prod-text {
        border: 1px solid #e61274;
        border-radius: 5px;
        color: #e61274;
        font-size: 12px;
        font-weight: 700;
        padding: 3px 7px 2px 7px;
      }

      .prod-actions {
        display: flex;
        text-align: center;

        .prod-act {
          margin: 0 10px;
          cursor: pointer;

          .wish {
            background-color: #adb1bc;
            color: #fff;
          }

          .cart {
            background: transparent
              linear-gradient(270deg, #ff7db9 0%, #e61274 100%) 0% 0% no-repeat
              padding-box;
          }

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 45px;
            margin: 0 auto;
            border-radius: 50%;
            box-shadow: 0px 5px 15px #00000026;
            margin-bottom: 5px;
          }

          span {
            color: #ff7db9;
            text-transform: uppercase;
            font-size: 9px;
            line-height: 9px;
            font-weight: 700;
            display: block;

            &.remove {
              color: #adb1bc;
            }
          }
        }
      }
    }
  }

  .empty-wishlist {
    color: #e61274;
    font-size: 28px;
    font-weight: 300;
    text-align: center;

    span {
      display: block;
      margin-top: 20px;
    }
  }

  .total-cart {
    width: 100%;
    max-width: 585px;
    margin-left: auto;
    margin-top: 30px;
    padding: 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .total-cart-body {
      display: flex;
      justify-content: space-between;

      .left {
        width: calc(50% - 15px);

        h4 {
          color: #e61274;
          font-size: 28px;
          font-weight: 300;
          margin-bottom: 10px;
        }
      }

      .right {
        width: calc(50% - 15px);

        li {
          display: flex;
          border-bottom: 1px solid #e4e7f1;
          justify-content: space-between;
          margin-bottom: 5px;

          span,
          p {
            font-size: 14px;
          }

          &.first {
            span,
            p {
              color: #e61274;
            }
          }
          &.second {
            span,
            p {
              color: #adb1bc;
            }
          }
          &.third {
            span,
            p {
              color: #950b4b;
            }
          }
        }
      }
    }
  }

  .inCheckout {
    padding: 0 15px;

    ${respondTo.md`
      padding: 0;
    `}
  }
`;

const ConfirmOrderStep = styled.div``;

const Cart = (props) => {
  const dispatch = useDispatch();

  const [orderCompleted, setOrderCompleted] = useState(false);
  const [isInCheckout, setIsInCheckout] = useState(false);

  const cart = useSelector((state) => state.user.user.cart);
  const userInfo = useSelector((state) => state.user.user.userinfo);
  const newOrder = useSelector((state) => state.product.newOrder);

  const removeFromWishlistHandler = (productId) => {
    dispatch(removeFromCart(productId));
  };

  const openOrderModalHandler = (product) => {
    dispatch(openOrderModal(product));
  };

  const moveToWishlistHandler = (productId) => {
    dispatch(removeFromCart(productId));
    dispatch(addToWishlist(productId));
  };

  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (cart) {
      let tot = 0;
      cart.forEach((prod) => {
        tot += prod.points;
      });

      setTotal(tot);
    }
  }, [cart]);

  return (
    <CartWrapper>
      <div className="slide">
        <img
          src={process.env.PUBLIC_URL + "/images/wishlist-slide.jpg"}
          alt=""
        />

        <div className="content">
          <div>
            <h3>Carrello</h3>
            <p>
              Qui ci sono i <strong>premi che hai selezionato</strong>: se hai
              accumulato tutti i punti necessari, puoi procedere con{" "}
              <strong>l’ordine</strong>.<br />
              <br />
              Non hai abbastanza punti per ordinare i tuoi premi preferiti?{" "}
              <strong>Salvali nella wishlist</strong> e poi torna a ordinarli.
            </p>
          </div>
        </div>
      </div>

      <div className="wish-products">
        {cart && cart.length > 0 ? (
          cart.map((prod) => (
            <div className="wish-product" key={prod.id}>
              <div className="prod-info">
                <Link to={`/prodotto/${prod.id}`} className="prod-image">
                  <img src={prod.image} alt="" />
                </Link>

                <div className="prod-data">
                  <span>{prod.brand}</span>
                  <Link to={`/prodotto/${prod.id}`}>{prod.title}</Link>
                  <p>{prod.short_description}</p>
                </div>
              </div>
              <div className="prod-text prod-points">{prod.points} Pt</div>
              <div className="prod-actions">
                <div
                  className="prod-act"
                  //onClick={() => openOrderModalHandler(prod)}
                  onClick={() => moveToWishlistHandler(prod.id)}
                >
                  <div className="cart">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/icons/favorite-white-icon.svg"
                      }
                      alt=""
                    />
                  </div>
                  <span>
                    Sposta nella
                    <br />
                    wishlist
                  </span>
                </div>
                <div
                  className="prod-act"
                  onClick={() => removeFromWishlistHandler(prod.id)}
                >
                  <div className="wish">
                    <X size={30} />
                  </div>
                  <span className="remove">
                    Rimuovi dal
                    <br />
                    carrello
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="empty-wishlist">
            <img
              src={process.env.PUBLIC_URL + "/icons/wishlist-cart-empty.svg"}
              alt=""
            />
            <span>
              Il tuo carrello è vuoto, aggiungi qualcosa o mettilo nella
              wishlist per dopo!
            </span>
            <Link to="catalogo">
              <Button
                upper
                gradient={{ left: "#E61274", right: "#FF7DB9" }}
                hoverGradient={{ left: "#E61274", right: "#E61274" }}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 20,
                }}
              >
                Vai al catalogo
                <ChevronRight size={30} />
              </Button>
            </Link>
          </div>
        )}
      </div>

      {cart && cart.length > 0 && !isInCheckout && (
        <div className="total-cart">
          <ContentBorderBox title="Totale carrello" color="#E61274">
            <div className="total-cart-body">
              <div className="left">
                {userInfo && (
                  <>
                    {userInfo.score - total >= 0 ? (
                      <>
                        <h4>Hai punti a sufficenza.</h4>
                        <p>
                          Hai accumulato i punti necessari: puoi ordinare i tuoi
                          premi preferiti!
                        </p>
                      </>
                    ) : (
                      <>
                        <h4>Non hai punti a sufficenza.</h4>
                        <p>
                          Continua ad accumulare punti e ottieni i tuoi premi
                          preferiti.
                        </p>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="right">
                <ul>
                  <li className="first">
                    <span>Tot. premi</span>
                    <p>{total}</p>
                  </li>
                  <li className="second">
                    <span>Tuoi punti</span>
                    <p>{userInfo && userInfo.score}</p>
                  </li>
                  <li className="third">
                    <span>
                      {userInfo && userInfo.score - total >= 0
                        ? "Rimane"
                        : "Necessari"}
                    </span>
                    <p>{userInfo && Math.abs(userInfo.score - total)}</p>
                  </li>
                </ul>
                {userInfo && userInfo.score - total >= 0 && (
                  <Button
                    upper
                    gradient={{ left: "#E61274", right: "#FF7DB9" }}
                    hoverGradient={{ left: "#E61274", right: "#E61274" }}
                    style={{ marginLeft: "auto", marginTop: 20 }}
                    onClick={() => setIsInCheckout(true)}
                  >
                    Checkout
                    <ChevronRight size={30} />
                  </Button>
                )}
              </div>
            </div>
          </ContentBorderBox>
        </div>
      )}

      {userInfo && isInCheckout && (
        <div className="inCheckout">
          <ContentBorderBox
            title={
              orderCompleted ? "Completa l'ordine" : "Indirizzo di spedizione"
            }
            color="#E61274"
          >
            {orderCompleted ? (
              <ConfirmOrderStep>
                <p>Sei sicuro di voler procedere con l'ordine?</p>

                <Button
                  upper
                  onClick={() => dispatch(orderProduct(cart))}
                  disabled={newOrder.loading}
                  gradient={{ left: "#E61274", right: "#FF7DB9" }}
                  hoverGradient={{ left: "#E61274", right: "#E61274" }}
                  style={{ marginTop: 20 }}
                >
                  {newOrder.loading ? (
                    <Loader inBtn show={newOrder.loading} color="#fff" />
                  ) : (
                    "Procedi e ordina"
                  )}
                </Button>
              </ConfirmOrderStep>
            ) : (
              <EditProfileStep
                confirmed={() => setOrderCompleted(true)}
                closed={() => setIsInCheckout(false)}
              />
            )}
          </ContentBorderBox>
        </div>
      )}

      <Modal
        show={newOrder.completed}
        close={() => {
          if (newOrder.completed) {
            dispatch(clearOrder());
          }
          setIsInCheckout(false);
          setOrderCompleted(false);
        }}
      >
        <p>
          Grazie per aver completato l'ordine, ti invieremo una email di
          riepilogo dell'ordine.
        </p>
      </Modal>
    </CartWrapper>
  );
};

export default Cart;
