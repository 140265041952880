export const theme = {
  font: "Roboto",
  primary: "#01cdff",
  overlay: "#292e32",
  default_color: "#3A3C42",
  secondary: "#cccccc",
  modal: "#f7f7f7",
  intialColorLoader: "#fff",
  bgboard: "#f4f4f4",
  bgcode: "#f4f4f4",
  borderCode: "#d0cfcf",
  btnRound: true,
  btnTextColor: "#fff",
  footer_desktop_height: 140,
  footer_mobile_height: 221,
  header_desktop_height: 93,
  header_mobile_height: 86,
  show_prize_in_home: 0,
  text: {
    primary: "#636669" /* body sul bianco */,
    secondary: "#fff" /* auth sull'overlay */,
    tertiary: "#cccccc",
  },
  input: {
    bg: "#f2f2f2",
    label: "#fff",
    labelModal: "#333333",
    error: "#ff0000",
  },
  isrect: false,
  textStyles: {
    vscream: {
      tag: "h1",
      fontSize: ["36px", "45px", "60px"],
      fontWeight: 100,
      lineHeight: ["40px", "55px", "70px"],
    },
    vbody: {
      tag: "p",
      fontSize: ["18px", "18px", "18px"],
      fontWeight: 100,
      lineHeight: ["20px", "20px", "20px"],
    },
    scream: {
      tag: "p",
      fontSize: ["32px", "45px", "60px"],
      fontWeight: 100,
      lineHeight: ["36px", "55px", "70px"],
    },
    body: {
      tag: "p",
      fontSize: ["22px", "22px", "26px"],
      fontWeight: 100,
      lineHeight: ["26px", "26px", "30px"],
    },
    body2: {
      tag: "p",
      fontSize: ["22px", "24px", "28px"],
      fontWeight: 100,
      lineHeight: ["26px", "28px", "32px"],
    },
    bscream: {
      tag: "p",
      fontSize: ["24px", "26px", "30px"],
      fontWeight: 100,
      lineHeight: ["28px", "30px", "34px"],
    },
    bscreamsmall: {
      tag: "p",
      fontSize: ["24px", "24px", "24px"],
      fontWeight: 100,
      lineHeight: ["28px", "28px", "28px"],
    },
    bbody: {
      tag: "p",
      fontSize: ["16px", "18px", "18px"],
      fontWeight: 100,
      lineHeight: ["20px", "24px", "24px"],
    },
    label: {
      tag: "p",
      fontSize: ["15px", "18px", "18px"],
      fontWeight: 100,
      lineHeight: ["18px", "24px", "24px"],
    },
    middlescream: {
      tag: "p",
      fontSize: ["1.5rem", "2rem"],
      fontWeight: 100,
      lineHeight: ["1.75rem", "2.25rem"],
    },
    title: {
      tag: "p",
      fontSize: ["28px", "40px", "46px"],
      fontWeight: 100,
      lineHeight: ["32px", "46px", "50px"],
    },
    text: {
      tag: "p",
      fontSize: ["18px", "19px", "21px"],
      fontWeight: 100,
      lineHeight: ["25px", "26px", "28px"],
    },
    smallLink: {
      tag: "p",
      fontSize: ["14px", "14px", "14px"],
      textDecoration: "underline",
      fontWeight: 100,
      lineHeight: ["14px", "14px", "14px"],
    },
    underline: {
      textDecoration: "underline",
    },
    infolabel: {
      tag: "p",
      fontSize: ["12px", "16px", "16px"],
      lineHeight: ["16px", "20px", "20px"],
      fontWeight: 100,
    },
    middlesc: {
      tag: "p",
      fontSize: ["26px", "42px", "42px"],
      lineHeight: ["30px", "46px", "46px"],
      fontWeight: 300,
    },
    middlep: {
      tag: "p",
      fontSize: ["18px", "22px", "22px"],
      lineHeight: ["22px", "26px", "26px"],
      fontWeight: 400,
    },
    titleGame: {
      tag: "p",
      fontSize: ["36px", "46px", "56px"],
      fontWeight: 100,
      lineHeight: ["40px", "50px", "60px"],
    },
    descGame: {
      tag: "p",
      fontSize: ["18px", "21px", "24px"],
      fontWeight: 100,
      lineHeight: ["22px", "24px", "28px"],
    },
    question: {
      tag: "p",
      fontSize: ["18px", "24px", "24px"],
      fontWeight: 400,
      lineHeight: ["21px", "28px", "28px"],
    },
    answer: {
      tag: "p",
      fontSize: ["18px", "21px", "21px"],
      fontWeight: 300,
      lineHeight: ["21px", "24px", "24px"],
    },
  },
  fontSizes: [11, 13, 14, 15, 16, 18, 20, 21, 22, 24, 26, 28, 32, 36, 52],
  breakpoints: ["48em", "64em", "48em"],
  awesomegrid: {
    mediaQuery: "only screen",
    columns: {
      xs: 4,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    gutterWidth: {
      xs: 1,
      sm: 1,
      md: 1.5,
      lg: 1.5,
      xl: 1.5,
    },
    paddingWidth: {
      xs: 1,
      sm: 1,
      md: 1.5,
      lg: 1.5,
      xl: 1.5,
    },
    container: {
      xs: "full", // 'full' = max-width: 100%
      sm: "full", // 'full' = max-width: 100%
      md: "full", // 'full' = max-width: 100%
      lg: 90, // max-width: 1440px
      xl: 90, // max-width: 1440px
    },
    breakpoints: {
      xs: 1,
      sm: 48, // 768px
      md: 64, // 1024px
      lg: 90, // 1440px
      xl: 120, // 1920px
    },
  },
};

export const configTheme = (appConfig) => {
  if (appConfig && appConfig.design && appConfig.design.template_props) {
    const {
      color_primary,
      color_secondary,
      isrect,
      font,
      button_textcolor,
      button_noround,
      footer_desktop_height,
      footer_mobile_height,
      header_desktop_height,
      header_mobile_height,
      show_prize_in_home,
      color_tertiary,
    } = appConfig.design.template_props;

    if (font) {
      theme.font = font;
    }
    if (color_primary) {
      theme.primary = `${color_primary}`;
    }

    if (color_secondary) {
      theme.secondary = `${color_secondary}`;
    }

    if (color_tertiary) {
      theme.tertiary = `${color_tertiary}`;
    }

    if (isrect) {
      theme.isrect = isrect;
    }
    if (button_noround) {
      theme.btnRound = button_noround;
    }
    if (button_textcolor) {
      theme.btnTextColor = button_textcolor;
    }
    if (footer_desktop_height) {
      theme.footer_desktop_height = footer_desktop_height;
    }
    if (footer_mobile_height) {
      theme.footer_mobile_height = footer_mobile_height;
    }
    if (header_desktop_height) {
      theme.header_desktop_height = header_desktop_height;
    }
    if (header_mobile_height) {
      theme.header_mobile_height = header_mobile_height;
    }
    if (show_prize_in_home) {
      theme.show_prize_in_home = show_prize_in_home;
    }
  }
};
