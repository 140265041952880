import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Loader, CountDown, Steps, StartGame, Button } from "../../../Ui";
import Fade from "../../../Fade";
import { Link } from "react-router-dom";
import Answers from "./Answers";
import { X } from "@styled-icons/boxicons-regular";
import Question from "./Question";
import styled from "styled-components";
import { respondTo } from "../../../../theme/mixin";
import Dynamic from "../../Dynamics/Dynamic";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ChevronLeft, ChevronRight } from "@styled-icons/boxicons-regular";
import { isBetween, getSlug, getElement } from "../../../../utils/utils";
import { FastForward, Gift } from "styled-icons/boxicons-regular";
import { darken } from "polished";
import ContentBorderBox from "../../../ContentBorderBox/ContentBorderBox";

const Container = styled.div`
  background-image: ${(props) => `url(${props.bg})`};
  background-position: center center;
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const QuizWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${respondTo.md`
    flex-wrap: nowrap;
  `};

  .survey-close {
    position: absolute;
    top: 0;
    right: 0;

    a {
      color: #adb1bc;
    }
  }

  .answers-list {
    width: 100%;
    padding-right: 15px;

    ${respondTo.md`
      width: 250px;
      border-right: 1px solid #e4e7f1;
    `};

    h4 {
      color: #7addff;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 15px;
    }

    .question-box {
      min-height: 37px;
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      background-color: #e4e7f1;
      color: #adb1bc;
      border-radius: 10px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      padding: 0 15px;

      &.completed {
        color: #fff;
        background-color: #00abe5;
      }

      &.active {
        color: #00abe5;
        background: transparent
          linear-gradient(270deg, #ffffff 0%, #7addff 100%) 0% 0% no-repeat
          padding-box;
      }
    }

    .answers-points {
      margin-top: 20px;
      margin-bottom: 10px;

      svg {
        color: #00abe5;
        position: relative;
        top: -2px;
        margin-right: 5px;
      }

      strong,
      span {
        color: #00abe5;
        font-size: 14px;
      }
    }
  }

  .answer-box {
    width: 100%;

    ${respondTo.md`
      padding-left: 15px;
    `};

    h4 {
      color: #7addff;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 15px;
    }

    .current-question {
      font-size: 28px;
      font-weight: 300;
    }

    .answer-footer {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      gap: 10px;
      ${respondTo.md`
      flex-wrap:nowrap;
flex-direction:row;
      justify-content: flex-end;
    `};

      button {
        margin-left: 15px;
        justify-content: center;
      }
    }
  }
`;

const QuizContainer = styled.div`
  margin: 0px auto;
  padding: 0px;
  max-width: 100%;
  padding-bottom: 10px;
  min-height: 640px;
  position: relative;
  box-sizing: border-box;
  ${respondTo.md`
    width: 520px;
    margin: 24px auto;
    padding: 8px;
    max-width: 90%;
    min-height: 640px;
    position: relative;
    box-sizing: border-box;
  `};
  ${respondTo.sm`
    width: 520px;
  `};
  ${respondTo.md`
    width: 520px;
  `};
`;

const SlideContainer = styled.div`
  text-align: center;
`;

const StepsContainer = styled.div``;

const StepsCountContainer = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  margin: 12px auto 0;
  justify-content: ${(props) => (props.isTimer ? "space-between" : "flex-end")};
`;

const NextContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  & span {
    display: block;
  }
  & svg {
    margin-left: 3px;
  }
  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.secondary)};
  }
`;

const SurveyQuiz = (props) => {
  const {
    lang,
    typeInteraction,
    onShowResult,
    g: {
      interaction,
      preinteraction,
      id,
      log: { sid },
      i18l: {
        info: { title, content },
      },
    },
    action: { data: dataAction },
  } = props;

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === "preinteraction") {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  const {
    id: idGame,
    type,
    settings: {
      instantcheck,
      selectedcheck,
      showtrueanswer,
      max,
      random,
      time,
      timer,
      next,
      steps,
      onlywin,
      aspect: { bggame, colorWin, colorLoose, colorAnswers },
    },
    i18l: {
      [lang]: { results, game },
    },
  } = rules;
  const carRef = useRef(null);

  const [cont, setCont] = useState([]);
  const [selected, setSelected] = useState([]);
  const [resultData, setResultData] = useState({});
  const [answers, setAnswers] = useState([]);
  const [index, setIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [startCountDown, setStartCountDown] = useState(false);
  const [startGame, setStartGame] = useState(!timer);
  const [step, setStep] = useState(1);
  const [timeComplete, setTimeCompleted] = useState(false);
  const [pause, setPause] = useState(false);
  const [goToNextByClick, setGoToNextByClick] = useState(false);
  const [action, setAction] = useState(false);

  //const [currentQuestion, setCurrentQuestion] = useState(0);

  const nextHandler = () => {
    setCurrentSlide(currentSlide + 1);
    setTimeCompleted(false);
  };

  const selectAnswerHandler = (e, obj, multi_answer) => {
    let oldAnswers = [...answers];

    if (Number(multi_answer)) {
      const find = oldAnswers.find(
        (el) => el.questionid === obj.questionid && el.answerid === obj.answerid
      );

      if (find) {
        oldAnswers.splice(
          oldAnswers.findIndex(
            (item) =>
              item.questionid === obj.questionid &&
              item.answerid === obj.answerid
          ),
          1
        );
      } else {
        oldAnswers.push(obj);
      }

      setAnswers(oldAnswers);
    } else {
      const newAnswers = oldAnswers.filter(function (el) {
        return el.questionid !== obj.questionid;
      });
      newAnswers.push(obj);
      setAnswers(newAnswers);
    }
  };

  const checkFinish = (answers, selected) => {
    setTimeout(() => {
      createResult(answers);
    }, 250);
  };

  const createResult = (answers) => {
    if (type === "quiz") {
      const totalPoints = answers.reduce(function (prev, current) {
        return prev + parseInt(current.points);
      }, 0);

      const haveYouWin = answers.filter((a) => a.result === "1");
      const rightanswers = { rightanswers: haveYouWin.length.toString() };
      const totalquestions = { totalquestions: answers.length.toString() };
      const haveyouwin = { haveyouwin: haveYouWin };

      const won = haveYouWin.length === selected.length ? true : false;

      for (const prop in results) {
        const result = isBetween(
          totalPoints,
          parseInt(prop.split("-")[0]),
          parseInt(prop.split("-")[1])
        );
        if (result) {
          const data = {
            profile: { ...results[prop] },
            attempts: rightanswers.rightanswers,
            nAttempts: totalquestions.totalquestions,
            onlywin,
            won,
            answers,
          };
          setResultData(data);
        }
      }
    }
    if (type === "survey") {
      let p = answers.map((a) => a.points);
      let r = p.reduce(
        (b, c) => (
          (
            b[b.findIndex((d) => d.el === c)] ||
            b[b.push({ el: c, count: 0 }) - 1]
          ).count++,
          b
        ),
        []
      );
      let max = Math.max.apply(
        Math,
        r.map((o) => o.count)
      );
      let last = r.filter((r) => r.count === max);
      let el = null;

      if (last.length > 0) {
        if (last.length === 1) {
          el = last[0].el;
        } else {
          let elArr = last.reduce(function (prev, curr) {
            return prev.el < curr.el ? prev : curr;
          });
          el = elArr.el;
        }
      }

      const data = {
        profile: { ...results[el] },
        answers,
      };

      setResultData(data);
    }
  };

  const navigateSlideHandler = (type) => {
    if (type === "back") {
      if (currentSlide >= 1) {
        setCurrentSlide(currentSlide - 1);
      }
    }
    if (type === "forward") {
      if (selected.length > currentSlide) {
        setCurrentSlide(currentSlide + 1);
      }
    }
  };

  useEffect(() => {
    init();
    setCurrentSlide(0);
    return () => {};
  }, []);

  const init = () => {
    if (random) {
      let shuffled = game.sort(() => 0.5 - Math.random());
      setSelected(shuffled.slice(0, max));
    } else {
      setSelected(game);
    }
  };

  useEffect(() => {
    let d = [];
    if (selected.length > 0) {
      d = selected.map((el) => {
        return (
          <SlideContainer key={el.id}>
            <div>
              <Question media={el.media} pause={pause}>
                {el.text}
              </Question>
              <Answers
                colorAnswers={colorAnswers}
                colorWin={colorWin}
                colorLoose={colorLoose}
                options={el.options}
                id={el.id}
                clicked={selectAnswerHandler}
                instantcheck={instantcheck}
                selectedcheck={selectedcheck}
                showtrueanswer={showtrueanswer}
                isStartDisabled={timer}
                currentSlide={currentSlide}
                completed={timeComplete}
              />
            </div>
          </SlideContainer>
        );
      });
    }

    setCont(d);

    if (timeComplete) {
      setGoToNextByClick(false);
    }
  }, [selected, timeComplete, pause]);

  /* useEffect(() => {
    setTimeout(() => {
      checkFinish(answers, selected);
    }, 500);

    setStartCountDown(false);
  }, [answers]); */

  useEffect(() => {
    if (goToNextByClick) {
      nextHandler();
    } else {
      return;
    }
  }, [goToNextByClick]);

  useEffect(() => {
    if (Object.entries(resultData).length !== 0) {
      setAction(true);
    }
  }, [resultData]);

  useEffect(() => {
    if (dataAction.length > 0) {
      let isData = getElement(dataAction, id);

      if (isData) {
        onShowResult();
      }
    }
  }, [dataAction]);

  return (
    <ContentBorderBox title={content} color="#00ABE5">
      {selected.length > 0 && (
        <QuizWrapper>
          <div className="survey-close">
            <Link to="/sondaggi/promo-survey">
              <X size={30} />
            </Link>
          </div>
          <div className="answers-list">
            <h4>Sondaggio in corso</h4>
            {selected.map((question, index) => {
              return (
                <div
                  className={`question-box ${index === currentSlide && "active"}
                  ${index < currentSlide && "completed"}
                  `}
                >
                  Domanda {index + 1}
                </div>
              );
            })}
            <div className="answers-points">
              <Gift size={18} />
              <strong>10 Pt </strong>
              <span>in palio</span>
            </div>
          </div>
          <div className="answer-box">
            <div className="current-question">
              <h4>Domanda {currentSlide + 1}</h4>
              {selected[currentSlide].text}
            </div>

            {selected.map((answer, index) => {
              if (answer.id === currentSlide + 1) {
                return (
                  <Answers
                    colorAnswers={colorAnswers}
                    colorWin={colorWin}
                    colorLoose={colorLoose}
                    options={answer.options}
                    id={answer.id}
                    clicked={(e, answ) =>
                      selectAnswerHandler(e, answ, answer.multi_answer)
                    }
                    instantcheck={instantcheck}
                    selectedcheck={selectedcheck}
                    showtrueanswer={showtrueanswer}
                    isStartDisabled={timer}
                    currentSlide={currentSlide}
                    completed={timeComplete}
                    selectedAnswers={answers.filter(
                      (a) => Number(a.questionid) === answer.id
                    )}
                  />
                );
              }
              return null;
            })}

            <div className="answer-footer">
              {currentSlide > 0 && (
                <Button
                  upper
                  gradient={{ left: "#ADB1BC", right: "#ADB1BC" }}
                  onClick={() => navigateSlideHandler("back")}
                >
                  <ChevronLeft size={30} />
                  Indietro
                </Button>
              )}

              <Button
                disabled={
                  !answers.find(
                    (a) => Number(a.questionid) === currentSlide + 1
                  )
                }
                upper
                gradient={{ left: "#00ABE5", right: "#7ADDFF" }}
                onClick={() =>
                  selected.length === currentSlide + 1
                    ? checkFinish(answers, selected)
                    : navigateSlideHandler("forward")
                }
              >
                Conferma
                <ChevronRight size={30} />
              </Button>
            </div>

            {/* <NextContainer
              onClick={nextHandlerFromClick}
              disabled={goToNextByClick}
            >
              <FastForward size={50} />
            </NextContainer> */}
          </div>
        </QuizWrapper>
      )}

      {/* {cont.length > 0 ? (
        <Container bg={bggame}>
          {startGame ? (
            <QuizContainer>
              <Fade show={true}>
                <Carousel
                  dynamicHeight={true}
                  selectedItem={currentSlide}
                  onChange={updateCurrentSlide}
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeable={false}
                  ref={carRef}
                  onChange={changeSlideHandler}
                >
                  {cont}
                </Carousel>
                <StepsCountContainer isTimer={timer}>
                  {timer ? (
                    timeComplete ? (
                      next !== "auto" ? (
                        <NextContainer
                          onClick={nextHandlerFromClick}
                          disabled={goToNextByClick}
                        >
                          <FastForward size={50} />
                        </NextContainer>
                      ) : null
                    ) : (
                      <CountDown
                        clicked={startCountDownHandler}
                        start={startCountDown}
                        time={time}
                        startgame={startGameHandler}
                        rend={renderer}
                        timeComplete={timeComplete}
                        gotonext={nextHandlerFromClick}
                        goToNextByClick={goToNextByClick}
                      />
                    )
                  ) : null}
                  <NextContainer
                    onClick={nextHandlerFromClick}
                    disabled={goToNextByClick}
                  >
                    <FastForward size={50} />
                  </NextContainer>

                  {steps && (
                    <StepsContainer>
                      <Steps current={step} total={selected.length} />
                    </StepsContainer>
                  )}
                </StepsCountContainer>
              </Fade>
            </QuizContainer>
          ) : (
            <StartGame clicked={startGameHandler} />
          )}
        </Container>
      ) : (
        <Loader show={true} full />
      )} */}
      {action ? (
        <Dynamic
          id={id}
          action={props.action}
          slug={slug}
          section={typeInteraction}
          resultData={resultData}
          sid={sid}
          {...props}
        />
      ) : null}
    </ContentBorderBox>
  );
};

SurveyQuiz.propTypes = {
  option: PropTypes.string,
  id: PropTypes.number,
};

export default SurveyQuiz;
