import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import { Button, Loader, Modal } from "../../components/Ui";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { respondTo } from "../../theme/mixin";
import { ChevronLeft } from "@styled-icons/boxicons-regular";
import { getSingleProduct, openOrderModal } from "../../store/actions/products";
import {
  addToWishlist,
  removeFromWishlist,
  addToCart,
} from "../../store/actions/wishlist";

const ProductWrapper = styled.div`
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${respondTo.md`
    flex-wrap: nowrap;
  `}

  .product-image {
    position: relative;
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 5px 15px #00000029;
    height: 250px;
    margin: 0 15px;

    ${respondTo.md`
      width: calc(40% - 15px);
      height: auto;
      margin: 0;
    `}

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    .add-to-wishlist {
      cursor: pointer;
      position: absolute;
      bottom: 10px;
      left: 10px;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: transparent linear-gradient(270deg, #ff7db9 0%, #e61274 100%)
        0% 0% no-repeat padding-box;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #ff7db9;
      }
    }
  }

  .product-info {
    width: 100%;
    padding: 0 15px;
    margin-top: 40px;

    ${respondTo.md`
      padding: 0;
      margin-top: 0;
      width: calc(60% - 15px);
    `}

    .info-box-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      flex-wrap: wrap;

      ${respondTo.md`
        flex-wrap: nowrap;
      `}

      .header-box {
        display: flex;
        width: 100%;
        margin: 7px 0;

        ${respondTo.md`
          margin: 0;
          width: auto;
        `}

        .box-info {
          margin-left: 5px;

          p {
            font-size: 14px;
            color: #adb1bc;
          }

          strong {
            color: #adb1bc;
          }

          &.primary {
            p {
              color: #ff7db9;
            }

            strong {
              color: #e61274;
            }
          }
        }
      }
    }

    h3 {
      font-size: 28px;
      color: #e61274;
      margin-bottom: 10px;
    }
    .product-description {
      margin-bottom: 30px;
    }
    .product-shipment {
      display: flex;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 30px;

      p {
        font-size: 14px;
        color: #ff7db9;
        margin-left: 5px;
      }
    }

    .product-buttons {
      display: flex;
    }
  }
`;

const Product = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [inWishlist, setInWishlist] = useState(false);
  const [modalAccess, setModalAccess] = useState(false);

  const wishlist = useSelector((state) => state.user.user.wishlist);
  const product = useSelector((state) => state.product.product);
  const products = useSelector((state) => state.products.products);
  const loading = useSelector((state) => state.product.loading);
  const isLogged = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (wishlist && product) {
      const exist = wishlist.find((el) => el.id === product.mProductId);
      if (exist) {
        setInWishlist(true);
      } else {
        setInWishlist(false);
      }
    }
  }, [wishlist, product]);

  useEffect(() => {
    if (id) {
      dispatch(getSingleProduct(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (product && product.mProductId) {
      if (product.mProductPoints === 1 && product.mTagliSet) {
        history.push("/");
      }
    }
  }, [product]);

  const openOrderModalHandler = () => {
    const selectedProd = products.find(
      (prod) => prod.id === product.mProductId
    );

    if (selectedProd) {
      dispatch(openOrderModal(selectedProd));
    }
  };

  const addToWishlistHandler = (productId) => {
    dispatch(addToWishlist(productId));
  };

  const removeFromWishlistHandler = (productId) => {
    dispatch(removeFromWishlist(productId));
  };

  return (
    <>
      <ProductWrapper>
        {loading ? (
          <Loader show={loading} />
        ) : (
          <>
            <div className="product-image">
              <img src={product.mImg} alt="" />
              <div
                className="add-to-wishlist"
                onClick={() => {
                  if (!isLogged) {
                    setModalAccess(true);
                  } else if (inWishlist) {
                    removeFromWishlistHandler(product.mProductId);
                  } else {
                    addToWishlistHandler(product.mProductId);
                  }
                }}
              >
                {inWishlist ? (
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/icons/favorite-white-icon-full.svg"
                    }
                    alt=""
                  />
                ) : (
                  <img
                    src={
                      process.env.PUBLIC_URL + "/icons/favorite-white-icon.svg"
                    }
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="product-info">
              <ContentBorderBox color="#e61274" position="right">
                <div className="info-box-header">
                  <div className="header-box">
                    <img
                      src={process.env.PUBLIC_URL + "/icons/punti-icon.svg"}
                      alt=""
                    />
                    <div className="box-info primary">
                      <p>Punti</p>
                      <strong>{product.mProductPoints}</strong>
                    </div>
                  </div>
                  <div className="header-box">
                    <img
                      src={process.env.PUBLIC_URL + "/icons/categoria-icon.svg"}
                      alt=""
                    />
                    <div className="box-info">
                      <p>Categoria</p>
                      <strong>{product.mCategoria}</strong>
                    </div>
                  </div>
                  <div className="header-box">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/icons/codice-prodotto-icon.svg"
                      }
                      alt=""
                    />
                    <div className="box-info">
                      <p>Codice prodotto</p>
                      <strong>{product.mCodiceEvasione}</strong>
                    </div>
                  </div>
                </div>

                <span>{product.mProductBrand}</span>

                <h3>{product.mProductShortDescription}</h3>

                <p className="product-description">
                  {product.mProductDescription}
                </p>

                {/* <div className="product-shipment">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/spedizione-icon.svg"}
                    alt=""
                  />
                  <p>Tempo di consegna previsto {product.mDelay} giorni</p>
                </div> */}

                <div className="product-buttons">
                  <Button
                    upper
                    gradient={{ left: "#E4E7F1", right: "#ADB1BC" }}
                    hoverGradient={{ left: "#FF7DB9", right: "#E61274" }}
                    onClick={() => history.goBack()}
                  >
                    <ChevronLeft size={30} /> Indietro
                  </Button>
                  <Button
                    upper
                    gradient={{ left: "#E61274", right: "#FF7DB9" }}
                    hoverGradient={{ left: "#FF7DB9", right: "#FF7DB9" }}
                    //onClick={() => openOrderModalHandler()}
                    onClick={() => dispatch(addToCart(product.mProductId))}
                    style={{ marginLeft: 10 }}
                  >
                    Aggiungi
                  </Button>
                </div>
              </ContentBorderBox>
            </div>
          </>
        )}
      </ProductWrapper>
      <Modal show={modalAccess} close={() => setModalAccess(false)}>
        Devi eseguire l'accesso prima di inserire prodotti in wishlist.
        <Link
          to={{
            pathname: "https://mylinkempage.linkem.com/MyLinkPlus",
          }}
          target="_blank"
        >
          <Button
            upper
            gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
            style={{ marginTop: 20 }}
          >
            Accedi
            <ChevronRight size={30} />
          </Button>
        </Link>
      </Modal>
    </>
  );
};

export default Product;
