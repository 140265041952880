import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTransition, useChain, animated } from "react-spring";
import { X } from "@styled-icons/boxicons-regular";

const Overlay = styled(animated.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  z-index: 99999;
`;

const Content = styled(animated.div)`
  width: ${(props) => {
    switch (props.size) {
      case "lg":
        return "800";
      default:
        return "500";
    }
  }}px;
  max-width: 90%;
  background-color: ${(props) =>
    props.theme.modal ? props.theme.modal : "#fff"};
  box-shadow: 0px 5px 15px #00000026;
  position: absolute;
  padding: 30px;
  top: 50px;
  border-radius: 30px;
  z-index: 998;
`;

const ModalContainer = styled.div`
  position: relative;
`;

const CloseModal = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: #e61274;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 999;
  cursor: pointer;
`;

const Modal = (props) => {
  const { close, children, show } = props;

  const overlayTransitionRef = useRef();
  const overlayTransition = useTransition(show, null, {
    ref: overlayTransitionRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const modalTransitionRef = useRef();
  const modalTransition = useTransition(show, null, {
    ref: modalTransitionRef,
    unique: true,
    trail: 100,
    from: { opacity: 0, transform: "translateY(-40px)" },
    enter: { opacity: 1, transform: "translateY(0)" },
    leave: { opacity: 0, transform: "translateY(-40px)" },
  });

  useChain(
    show
      ? [overlayTransitionRef, modalTransitionRef]
      : [modalTransitionRef, overlayTransitionRef],
    [0, show ? 0.1 : 0.6]
  );

  return overlayTransition.map(({ item, key, props }) =>
    item ? (
      <Overlay key={key} style={props}>
        {modalTransition.map(({ item, key, props }) =>
          item ? (
            <Content key={key} style={props}>
              <CloseModal onClick={close}>
                <X size={30} />
              </CloseModal>
              <ModalContainer>{children}</ModalContainer>
            </Content>
          ) : null
        )}
      </Overlay>
    ) : null
  );
};

Modal.propTypes = {
  close: PropTypes.func,
  children: PropTypes.any,
  show: PropTypes.bool,
};

export default Modal;
