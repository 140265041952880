import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function useCarnevale({ id } = {}) {
  const [showcarnevale, setShowcarnevale] = useState(true);
  /*   const { start, end } = useSelector(
    (state) =>
      state.game.games.find(
        (item) => +item.id === +process.env.REACT_APP_GAME_CARNEVALE
      )?.settings || {}
  ); */

  const { start, end } = useSelector(
    (state) =>
      state.promo.allpromo
        .find((item) => item.name === "vinciancoradipiu")
        ?.games.find(
          (item) => +item.id === +process.env.REACT_APP_GAME_CARNEVALE
        )?.settings || {}
  );

  useEffect(() => {
    if (start && end) {
      const start1 = new Date(start.replace(/-/g, "/")).getTime();
      const end1 = new Date(end.replace(/-/g, "/")).getTime();
      const today = new Date().getTime();
      if (id) {
        setShowcarnevale(
          Boolean(today >= start1 && today <= end1) &&
            (+id === 1 ||
              +id === 2 ||
              +id === +process.env.REACT_APP_GAME_CARNEVALE)
        );
      } else {
        setShowcarnevale(Boolean(today >= start1 && today <= end1));
      }
    } else {
      setShowcarnevale(false);
    }
  }, [start, end]);
  return showcarnevale;
}

export default useCarnevale;
