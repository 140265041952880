import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { SurveyQuiz } from "../../Interactions";
import { Loader } from "../../../Ui";
import { getSlug } from "../../../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import PreInteraction from "../../PreInteraction";
import ResultGame from "../../Response/ResultGame";
import styled from "styled-components";
import { X } from "@styled-icons/boxicons-regular";
import { respondTo } from "../../../../theme/mixin";
import SurveysList from "../../../../components/SurveysList/SurveysList";
import { getGames } from "../../../../store/actions";
import ContentBorderBox from "../../../ContentBorderBox/ContentBorderBox";

const SurveyQuizWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 370px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }

  .survey-content {
    padding: 0 15px;
    position: relative;
    .catalog-link {
      margin-top: 15px;
      display: inline-block;
    }
    ${respondTo.md`
      padding: 0;
    `}

    .survey-close {
      position: absolute;
      top: 0;
      right: 0;

      a {
        color: #adb1bc;
      }
    }
  }
`;

const QuizResultText = styled.div`
  padding-bottom: 10px;

  h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  img {
    width: 285px;
    height: auto;
  }
`;

const SurveyQuizHandler = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    g: {
      preinteraction,
      log: { steps },
    },
    played,
    action: { loading, data },
  } = props;
  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }

  const games = useSelector((state) => state.game.games);
  const isLogged = useSelector((state) => state.auth.token);

  const slug = getSlug();

  useEffect(() => {
    if (slug && isLogged && games.length === 0) {
      dispatch(getGames(slug, isLogged));
    }
  }, [dispatch, isLogged, slug, games]);

  useEffect(() => {
    if (props.g && props.g.log.playslog) {
      history.push("/sondaggi/promo-survey");
    }
  }, [history, props.g]);

  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction);

  let content = null;

  const onShowResult = () => {
    dispatch(getGames(slug, isLogged));
    setShowResult(true);
  };

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };

  const scrollRef = useRef(null);

  const executeScroll = () => scrollRef.current.scrollIntoView();

  useEffect(() => {
    if (!loading) {
      let timer = setTimeout(() => executeScroll(), 300);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [loading]);

  content = (
    <SurveyQuizWrapper>
      <div className="slide">
        <img
          src={process.env.PUBLIC_URL + "/images/sondaggi-background.jpg"}
          alt=""
        />

        <div className="content">
          <div>
            <h3>Conosciamoci di più</h3>
            <p>
              In questa sezione trovi l’elenco dei{" "}
              <strong>sondaggi da sbloccare</strong>: rispondi alle domande e
              accumula punti da spendere nel nostro catalogo. Puoi iniziare a
              rispondere a un sondaggio e decidere se finirlo subito oppure
              completarlo più tardi.
              <br />
              <br />
              Nel box in basso puoi controllare i{" "}
              <strong>sondaggi completati</strong>, quelli iniziati e avere
              un’anteprima di quelli in arrivo.
              <br />
              <br />
              Rispondi subito alle domande e{" "}
              <strong>inizia ad accumulare punti!</strong>
            </p>
          </div>
        </div>
      </div>

      <div className="survey-content">
        <SurveysList />
        {loading ? (
          <Loader show={loading} full />
        ) : showResult || played !== null ? (
          <ContentBorderBox title={props.g.i18l.info.content} color="#00ABE5">
            <div className="survey-close">
              <Link to="/sondaggi/promo-survey">
                <X size={30} />
              </Link>
            </div>
            <QuizResultText>
              <h3>
                {data.filter((res) => +res.id === +props.g.id)[0] &&
                  JSON.parse(
                    data.filter((res) => +res.id === +props.g.id)[0].data
                  ).profile.title}
              </h3>
              <p>
                {data.filter((res) => +res.id === +props.g.id)[0] &&
                  JSON.parse(
                    data.filter((res) => +res.id === +props.g.id)[0].data
                  ).profile.content}
              </p>
              {props?.g?.id === +process.env.REACT_APP_GAME_NATALE ? (
                <Link to="/catalogo" className="catalog-link">
                  <img
                    src={
                      data.filter((res) => +res.id === +props.g.id)[0] &&
                      JSON.parse(
                        data.filter((res) => +res.id === +props.g.id)[0].data
                      ).profile.media.src
                    }
                    alt=""
                    srcset=""
                  />
                </Link>
              ) : (
                <img
                  src={process.env.PUBLIC_URL + "/images/survey-finish.png"}
                  alt=""
                />
              )}
            </QuizResultText>
          </ContentBorderBox>
        ) : !showInteraction && steps.preinteraction.data === null ? (
          <PreInteraction
            preinteraction={preinteraction}
            onShowInteractionHandler={onShowInteractionHandler}
            {...props}
          />
        ) : (
          <SurveyQuiz
            typeInteraction="interaction"
            onShowResult={onShowResult}
            {...props}
          />
        )}
        <span ref={scrollRef} />
      </div>
    </SurveyQuizWrapper>
  );

  return content;
};

SurveyQuizHandler.propTypes = {
  action: PropTypes.object,
};

export default SurveyQuizHandler;
