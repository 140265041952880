import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import moment from "moment";
import {
  Loader,
  CustomContainer,
  Button,
  Input,
  Modal,
} from "../../components/Ui";
import { useLastLocation } from "react-router-last-location";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import useForm from "../../hooks/useForm";
import {
  ChevronRight,
  UpArrowAlt,
  DownArrowAlt,
  ChevronDown,
  Gift,
  PurchaseTag,
} from "@styled-icons/boxicons-regular";
import { Truck } from "@styled-icons/boxicons-solid";
import { profileform } from "../../formsConfig/formsConfig";
import ModalMessage from "../../components/ModalMessage";
import EditProfile from "./EditProfile";
import { deleteUser } from "../../store/actions";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import { Line } from "react-chartjs-2";
import ChangeProfileImageModal from "../../components/ChangeProfileImageModal/ChangeProfileImageModal";
import Avatar from "../../components/Avatar/Avatar";
import ReactShadowScroll from "react-shadow-scroll";

const ProfileContainer = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 370px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }

      .resume {
        margin-top: 20px;

        h4 {
          color: #eb5a3f;
          font-size: 20px;
        }

        .icons-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .box-icon {
            width: 50%;
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 10px;

            .icon {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              background-color: #eb5a3f;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .info {
              margin-left: 5px;

              span {
                color: #f4b375;
                display: block;
              }

              strong {
                color: #eb5a3f;
              }
            }
          }
        }
      }
    }

    .upload-profile {
      position: absolute;
      top: 125px;
      left: 110px;
      z-index: 100;

      & > div {
        width: 100px;
        height: 100px;
      }

      ${respondTo.md`
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        & > div {
          width: 270px;
          height: 270px;
        }
      `}

      .profile-image {
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.4);
      }

      .profile-btn {
        position: absolute;
        width: 100px;
        height: 100px;
        top: calc(50% - 50px);
        right: -50px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .first-row-boxs {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 15px;
    align-items: stretch;
    ${respondTo.md`
      padding: 0;
    `}

    .box-history-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      h4 {
        margin-left: 10px;
        font-size: 21px;
        font-weight: 700;
      }
    }

    .points-history-box {
      width: 100%;
      margin-bottom: 20px;

      ${respondTo.md`
        margin-bottom: 0;
        width: calc(65% - 15px);
      `}

      .box-header {
        margin-bottom: 10px;
      }

      .movments-wrapper {
        display: flex;
        flex-wrap: wrap;

        .chart-movments {
          width: 100%;
          height: 413px;
          padding-bottom: 30px;
          padding-right: 20px;

          ${respondTo.md`
            width: 63%;
          `}
        }

        .last-movments {
          width: 100%;
          margin-top: 40px;

          ${respondTo.md`
            width: 37%;
            margin-top: 0;
          `}
        }
      }

      h6 {
        color: #f2a55b;
        font-size: 18px;
        margin-bottom: 10px;
      }

      ul {
        max-height: 388px;
        // overflow-y: auto;

        li {
          display: flex;
          justify-content: space-between;
          padding-left: 10px;
          margin-top: 10px;

          &.positive {
            border-left: 5px solid #6cb967;

            svg,
            p {
              color: #6cb967;
            }
          }

          &.negative {
            border-left: 5px solid #eb5a3f;

            svg,
            p {
              color: #eb5a3f;
            }
          }

          .action-details {
            span {
              color: #adb1bc;
              font-size: 14px;
            }

            strong {
              display: block;
            }
          }
        }
      }
    }

    .orders-history-box {
      width: 100%;
      border-radius: 15px;
      background-color: #eb5a3f;
      padding: 15px 2px 15px 10px;
      .border-status {
        font-weight: bold;
        padding: 2px;
        border-radius: 3px;
        &.in-lavorazione {
          background: #fdca46;
        }
        &.evaso {
          background: #6cb967;
        }
        &.annullato {
          background: #e32a2a;
        }
      }
      ${respondTo.md`
        width: 35%;
      `}

      h4 {
        color: #fff;
      }

      ul {
        max-height: 450px;
        overflow-y: auto;
        .flexLi {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 5px;
          border-bottom: 1px solid #b73e27;
          margin-bottom: 10px;
        }
        li {
          width: 100%;
          display: flex;
          align-items: center;

          padding-bottom: 10px;
          ${respondTo.sm`
        width: 49%;
      `}
          &:nth-child(even) {
            .order-info {
              border-right: none;
            }
          }
          &:last-child {
            .order-info {
              border-right: none;
            }
          }
          .order-image {
            overflow: hidden;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            box-shadow: 0px 3px 10px #00000029;

            img {
              width: 100%;
            }
          }
          /*  .flex {
           
          
            gap: 10px;
            flex-wrap: wrap;
            
          } */
          .order-info {
            padding: 0 10px;
            width: 275px;
            border-right: none;
            flex: 1;
            ${respondTo.sm`
         border-right: 1px solid #f2a55b4a;
      `}
            ${respondTo.md`
         width: 142px;
      `}

            .order-points {
              color: #fff;
            }

            strong {
              display: block;
              font-size: 13px;
              color: #fdca46;
              margin: 5px 0;
            }

            span {
              color: #3a3c42;
              font-size: 13px;
            }

            .links {
              display: flex;
              flex-wrap: wrap;
              margin-top: 3px;

              a {
                cursor: pointer;
                color: #fdca46;
                text-transform: uppercase;
                font-size: 12px;
                display: inline-block;
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }

  .profile-boxs {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;

    ${respondTo.md`
      padding: 0;
      flex-wrap: nowrap;
    `}

    .shipment-box {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      margin-top: 20px;

      ${respondTo.md`
        margin-top: 0;
      `}
    }

    & > div {
      width: 100%;

      ${respondTo.md`
        width: calc(33.333% - 15px);
      `}
    }

    .info-box,
    .shipment-box {
      background-color: #f2a55b;
      border-radius: 15px;
      padding: 15px;
    }

    .coupon-history-box {
      margin-top: 20px;

      ${respondTo.md`
        margin-top: 0;
      `}

      & > div, .wrapper {
        height: 100%;
      }

      ul {
        width: 100%;
      }
    }

    .box-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      strong {
        margin-left: 10px;
        color: #fff;
        font-size: 21px;
      }
    }

    .box-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &.is-edit {
        .field-group,
        .field-group-half {
          margin-bottom: 0;
        }
      }

      .field-group-half {
        width: calc(50% - 10px);
        margin-bottom: 30px;
      }

      .field-group {
        width: 100%;
        margin-bottom: 30px;
      }

      span {
        display: block;
        color: #fdca46;
        font-weight: 700;
      }

      strong {
        font-size: 16px;
        color: #fff;
      }
    }

    .box-footer {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &.double {
        & > button {
          margin-top: 10px;
        }

        p {
          color: #fff;
          margin-top: 15px;

          ${respondTo.md`
            margin-top: 0;
          `}
        }
      }

      span {
        text-align: center;
        color: #fff;
        width: 100%;
        font-size: 16px;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }
  }
`;

const CouponList = styled.ul`
  max-height: 450px;
  overflow-y: auto;

  li {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #f3f3f3;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 5px;
  }

  .coupon-image {
    overflow: hidden;
    flex: 0 0 55px;
    height: 55px;
    border-radius: 50%;
    box-shadow: 0px 3px 10px #00000029;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .coupon-info {
    margin-left: 10px;

    span {
      color: #adb1bc !important;
      font-weight: 300 !important;
      font-size: 14px;
    }

    strong {
      color: #f2a55b !important;
      font-size: 14px;
    }
  }
`;

const Profile = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const lastLocation = useLastLocation();
  const [t] = useTranslation();
  let pathname = null;

  if (lastLocation) {
    pathname = lastLocation.pathname;
  }
  const {
    editUser,
    closeModalEdit,
    getUserIsLogged,
    userLogged,
    userInfo,
    updateModal,
    loading,
    logout,
    codes,
    token,
    toggleSidebar,
    deleteUser,
    deleteCompleted,
    userCouponList,
    partners,
    orders,
    actions,
  } = props;

  const [deleteAccountModal, setDeleteAccountModal] = useState(false);

  useEffect(() => {
    getUserIsLogged();
    return () => {
      closeModalEdit();
    };
  }, []);

  useEffect(() => {
    setShowSidebar(true);
  }, [userLogged]);

  useEffect(() => {
    if (updateModal) {
      setIsEdit(false);
    }
  }, [updateModal]);

  useEffect(() => {
    if (deleteCompleted) {
      setDeleteAccountModal(false);
    }
  }, [deleteCompleted]);

  const [chartPoints, setChartPoints] = useState([]);
  const [chartDate, setChartDate] = useState([]);
  const [changeImageModal, setChangeImageModal] = useState(false);

  useEffect(() => {
    if (actions) {
      const points = [];
      const date = [];

      const lastElements = actions.slice(Math.max(actions.length - 5, 0));
      lastElements.forEach((element) => {
        points.push(element.totalPoints);
        date.push(moment(element.created_at).format("DD/MM/YYYY"));
      });

      setChartPoints(points);
      setChartDate(date);
    }
  }, [actions]);

  return (
    <>
      <ProfileContainer>
        <div className="slide">
          <img
            src={process.env.PUBLIC_URL + "/images/profile-slide.jpg"}
            alt=""
          />

          <div className="content">
            <div>
              <h3>Il tuo profilo</h3>
              <p>
                In questa sezione puoi consultare i tuoi{" "}
                <strong>dati personali</strong>, il tuo saldo
                <strong> punti</strong>, lo <strong>storico ordini</strong>, il
                tuo livello di apparteneza o scaricare i codici coupon dei
                partner che hai scelto.
                <br />
                <br />
                Inoltre, puoi modificare la tua <strong>foto profilo</strong> e
                <strong> l'indirizzo di spedizione</strong> dei premi.
              </p>

              <div className="resume">
                <h4>In breve</h4>
                <div className="icons-list">
                  <div className="box-icon">
                    <div className="icon">
                      <Gift size={25} />
                    </div>
                    <div className="info">
                      <span>Hai</span>
                      <strong>{userInfo && userInfo.score} Pt</strong>
                    </div>
                  </div>
                  <div className="box-icon">
                    <div className="icon">
                      <Truck size={25} />
                    </div>
                    <div className="info">
                      <span>Hai richesto</span>
                      <strong>{orders && orders.length} premi</strong>
                    </div>
                  </div>
                  <div className="box-icon">
                    <div className="icon">
                      <PurchaseTag size={25} />
                    </div>
                    <div className="info">
                      <span>Hai richiesto</span>
                      <strong>
                        {userCouponList && userCouponList.length} Coupon
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="upload-profile">
            <Avatar size={270} />
            <div
              className="profile-btn"
              onClick={() => setChangeImageModal(true)}
            >
              <img
                src={process.env.PUBLIC_URL + "/icons/edit-profile.png"}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="first-row-boxs">
          <div className="points-history-box">
            <ContentBorderBox title="I tuoi punti catalogo" color="#EB5A3F">
              <div className="movments-wrapper">
                <div className="chart-movments">
                  <h6>Andamento punti</h6>
                  <Line
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                        },
                        y: {
                          grid: {
                            display: false,
                          },
                        },
                      },
                    }}
                    data={{
                      labels: chartDate,
                      datasets: [
                        {
                          data: chartPoints,
                          tension: 0.1,
                          backgroundColor: "rgba(235, 89, 63, 0.25)",
                          borderColor: "#EA5A3F",
                          borderWidth: 2,
                          label: false,
                          fill: true,
                        },
                      ],
                    }}
                  />
                </div>
                <div className="last-movments">
                  <h6>Ultimi movimenti</h6>
                  <ul>
                    <ReactShadowScroll
                      style={{ width: "100%", maxHeight: "450px" }}
                      isShadow={false}
                      scrollColor={"#EB5A3F"}
                      scrollColorHover="#ce9085"
                      styleSubcontainer={{ overflowX: "hidden" }}
                    >
                      {actions && (
                        <>
                          {actions.length > 0 ? (
                            actions
                              .slice(0)
                              .reverse()
                              .map((action) => {
                                let status = "positive";

                                if (action.points < 0) {
                                  status = "negative";
                                }

                                return (
                                  <li
                                    key={action.id}
                                    className={`${
                                      status === "positive"
                                        ? "positive"
                                        : "negative"
                                    }`}
                                  >
                                    <div className="action-details">
                                      <span>
                                        {moment(action.created_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </span>
                                      <strong>{action.details}</strong>
                                      <p>{action.points}</p>
                                    </div>
                                    {status === "positive" && (
                                      <UpArrowAlt size={30} />
                                    )}
                                    {status === "negative" && (
                                      <DownArrowAlt size={30} />
                                    )}
                                  </li>
                                );
                              })
                          ) : (
                            <p>Nessun movimento</p>
                          )}
                        </>
                      )}
                    </ReactShadowScroll>
                  </ul>
                </div>
              </div>
            </ContentBorderBox>
          </div>
          <div className="orders-history-box">
            <div className="box-history-header">
              <img
                src={process.env.PUBLIC_URL + "/icons/shipment-icon.svg"}
                alt=""
              />
              <h4>Storico premi</h4>
            </div>
            <div className="box-history-body">
              <ul>
                <ReactShadowScroll
                  style={{ width: "100%", maxHeight: "450px" }}
                  isShadow={false}
                  scrollColor={"#cccccc"}
                  scrollColorHover="gray"
                  styleSubcontainer={{ overflowX: "hidden" }}
                >
                  {orders && (
                    <>
                      {orders.length > 0 ? (
                        orders.map((order) => {
                          if (order.products.length > 0) {
                            return (
                              <div className="flexLi">
                                {order.products.map((prod) => (
                                  <li key={order.id}>
                                    <div className="order-image">
                                      <img src={prod.image} alt="" />
                                    </div>

                                    <div className="order-info">
                                      <div className="order-points">
                                        {prod.points} Pt.
                                      </div>
                                      <strong>{prod.brand}</strong>
                                      <span>
                                        {" "}
                                        {prod.short_description}
                                      </span>{" "}
                                      <br />
                                      <span
                                        className={
                                          order.status === "In lavorazione"
                                            ? "border-status in-lavorazione "
                                            : order.status === "Evaso"
                                            ? "border-status  evaso "
                                            : order.status === "Annullato"
                                            ? "border-status  annullato "
                                            : ""
                                        }
                                      >
                                        {order.status}
                                      </span>
                                      <br />
                                      {prod.links && (
                                        <div className="links">
                                          {prod.links.map((p, pIndex) => (
                                            <a
                                              target="_blank"
                                              href={p}
                                              rel="noopener noreferrer"
                                            >
                                              Download {pIndex + 1}
                                            </a>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                ))}
                              </div>
                            );
                          } else return null;
                        })
                      ) : (
                        <p>Nessun ordine</p>
                      )}
                    </>
                  )}
                </ReactShadowScroll>
              </ul>
            </div>
          </div>
        </div>

        <div className="profile-boxs">
          <div className="info-box">
            <div className="box-header">
              <img
                src={process.env.PUBLIC_URL + "/icons/profile-box-icon.svg"}
                alt=""
              />
              <strong>Dati personali</strong>
            </div>
            <div className="box-body">
              {userInfo && (
                <>
                  <div className="field-group-half">
                    <span>Nome</span>
                    <strong>{userInfo.firstname}</strong>
                  </div>
                  <div className="field-group-half">
                    <span>Cognome</span>
                    <strong>{userInfo.lastname}</strong>
                  </div>
                  <div className="field-group">
                    <span>Email</span>
                    <strong>{userInfo.email}</strong>
                  </div>
                  <div className="field-group">
                    <span>Telefono</span>
                    <strong>{userInfo.phone}</strong>
                  </div>
                  <div className="field-group">
                    <span>Telefono fisso</span>
                    <strong>-</strong>
                  </div>
                  <div className="field-group-half">
                    <span>Codice fiscale</span>
                    <strong>{userInfo.fiscalcode}</strong>
                  </div>
                  <div className="field-group-half">
                    <span>Cliente da</span>
                    <strong>-</strong>
                  </div>
                </>
              )}
            </div>

            <div className="box-footer double">
              <Link
                to={{
                  pathname: "https://mylinkempage.linkem.com",
                }}
                target="_blank"
              >
                <Button
                  gradient={{ left: "#B73E27", right: "#EB5A3F" }}
                  hoverGradient={{ left: "#EB5A3F", right: "#EB5A3F" }}
                  upper
                >
                  Gestione profilo
                </Button>
              </Link>
              {userInfo && userInfo.to_be_deleted_at ? (
                <p>
                  L'account sarà eliminato il{" "}
                  {moment(userInfo.to_be_deleted_at).format("DD/MM/YYYY")}
                </p>
              ) : (
                <Button
                  gradient={{ left: "#B73E27", right: "#EB5A3F" }}
                  hoverGradient={{ left: "#EB5A3F", right: "#EB5A3F" }}
                  upper
                  onClick={() => setDeleteAccountModal(true)}
                >
                  Elimina account
                </Button>
              )}
            </div>
          </div>
          <div className="shipment-box">
            <div className="box-header">
              <img
                src={process.env.PUBLIC_URL + "/icons/shipment-box-icon.svg"}
                alt=""
              />
              <strong>Indirizzo di spedizione</strong>
            </div>

            {!isEdit ? (
              <div className="box-body">
                {userInfo && (
                  <>
                    <div className="field-group-half">
                      <span>Nome</span>
                      <strong>{userInfo.shipment_firstname || "-"}</strong>
                    </div>
                    <div className="field-group-half">
                      <span>Cognome</span>
                      <strong>{userInfo.shipment_lastname || "-"}</strong>
                    </div>
                    <div className="field-group">
                      <span>Indirizzo</span>
                      <strong>{userInfo.address1 || "-"}</strong>
                    </div>
                    <div className="field-group">
                      <span>Città</span>
                      <strong>{userInfo.city || "-"}</strong>
                    </div>
                    <div className="field-group-half">
                      <span>Prov.</span>
                      <strong>{userInfo.district || "-"}</strong>
                    </div>
                    <div className="field-group-half">
                      <span>CAP</span>
                      <strong>{userInfo.zipcode || "-"}</strong>
                    </div>
                    <div className="field-group">
                      <span>Note (interno, piano, ecc.)</span>
                      <strong>{userInfo.address2 || "-"}</strong>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <EditProfile isEditChange={() => setIsEdit(false)} />
            )}

            {!isEdit && (
              <div className="box-footer">
                <Button
                  gradient={{ left: "#B73E27", right: "#EB5A3F" }}
                  hoverGradient={{ left: "#EB5A3F", right: "#EB5A3F" }}
                  upper
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Modifica indirizzo
                  <ChevronRight size={30} />
                </Button>
              </div>
            )}
          </div>
          <div className="coupon-history-box">
            <ContentBorderBox
              title="Storico coupon"
              icon={process.env.PUBLIC_URL + "/icons/coupon-history-icon.svg"}
              color="#F2A55B"
              fullheight
            >
              {userCouponList && userCouponList.length > 0 ? (
                <CouponList>
                  {userCouponList.map((coupon) => (
                    <li key={coupon.id}>
                      <div className="coupon-image">
                        <img src={coupon.partner.logo} alt="" />
                      </div>
                      <div className="coupon-info">
                        <span>
                          {moment(coupon.created_at).format("DD/MM/YYYY")}
                        </span>
                        <strong>{coupon.partner.name}</strong>
                        <p>{coupon.partner.subtitle}</p>
                        {coupon.code !==
                          "Clicca sul bottone e ottieni il tuo sconto" &&
                          coupon.code !==
                            "Clicca sul pulsante e ottieni il tuo sconto" &&
                          coupon.code !==
                            "Clicca sul bottone e ottieni il tuo vantaggio" &&
                          coupon.code !==
                            "Clicca sul pulsante e ottieni il tuo vantaggio" && (
                            <p>Codice: {coupon.code}</p>
                          )}

                        <p>
                          Scadenza:{" "}
                          {moment(coupon.partner.voucher_expires_at).format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                        <a
                          href={
                            coupon.partner.url.startsWith("http") ||
                            coupon.partner.url.startsWith("https")
                              ? coupon.partner.url
                              : `https://${coupon.partner.url}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visita il sito del partner
                        </a>
                      </div>
                    </li>
                  ))}
                </CouponList>
              ) : (
                <p>Nessun coupon acquistato.</p>
              )}
            </ContentBorderBox>
          </div>
        </div>
      </ProfileContainer>

      <Modal show={updateModal} close={() => closeModalEdit()}>
        Profilo aggiornato correttamente
      </Modal>

      <Modal
        show={deleteAccountModal}
        close={() => setDeleteAccountModal(false)}
      >
        Sei sicuro di voler eliminare definitivamente il tuo account?
        <Button
          gradient={{ left: "#B73E27", right: "#EB5A3F" }}
          hoverGradient={{ left: "#EB5A3F", right: "#EB5A3F" }}
          upper
          style={{ marginTop: 20 }}
          onClick={() => deleteUser()}
        >
          Conferma eliminazione
        </Button>
      </Modal>

      {changeImageModal && (
        <ChangeProfileImageModal onClose={() => setChangeImageModal(false)} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deleteCompleted: state.user.deleteCompleted,
    userLogged: state.user.userLogged,
    userInfo: state.user.user.userinfo,
    userCouponList: state.user.user.vouchers,
    partners: state.partner.partnerList,
    loading: state.user.loading,
    codes: state.user.user.codes,
    updateModal: state.user.updateModal,
    updateError: state.user.user.updateError,
    orders: state.user.user.orders,
    actions: state.user.user.actions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModalEdit: () => dispatch(actionCreators.closeModalEdit()),
    editUser: (data) => dispatch(actionCreators.editUser(data)),
    deleteUser: () => dispatch(deleteUser()),
    getUserIsLogged: () => dispatch(actionCreators.getUserIsLogged()),
    logout: () => dispatch(actionCreators.logout()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
