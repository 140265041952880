import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { filtersform } from "../../formsConfig/formsConfig";
import { useSelector, useDispatch } from "react-redux";
import useForm from "../../hooks/useForm";
import { Link } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import { ChevronRight, X, ChevronDown } from "@styled-icons/boxicons-regular";
import { Button, Input, Loader, Text } from "../../components/Ui";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import ProductBox from "../../components/ProductBox/ProductBox";
import Avatar from "../../components/Avatar/Avatar";
import { getProducts } from "../../store/actions/products";

const CatalogWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 270px;
        height: 100%;
        margin-top: 0;
      `}

      div {
        .contentTitle {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          h3 {
            margin-right: 10px;
          }
        }

        h3 {
          font-size: 28px;
          font-weight: 300;
        }

        span {
          display: block;
          width: 40px;
          height: 2px;
          background-color: #eb5a3f;
          margin-bottom: 20px;
        }
      }
    }
  }

  .boxs {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 15px;

    ${respondTo.md`
      flex-wrap: nowrap;
      padding: 0;
    `}

    .filter-box {
      width: 100%;
      background-color: #e61274;
      padding: 15px;
      border-radius: 15px;

      ${respondTo.md`
        width: calc(33.333% - 20px);
      `}

      h3 {
        color: #fff;
        font-size: 21px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      label {
        color: #ff7db9;
        font-size: 16px;
        font-weight: 300;
      }

      .filter-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #950b4b;
        padding-top: 40px;

        span {
          font-size: 16px;
          text-transform: uppercase;
          font-weight: 700;
          cursor: pointer;
          color: #fff;

          &:hover {
            color: #ff7db9;
          }
        }
      }
    }

    .wishlist-box {
      width: 100%;
      margin-top: 20px;

      ${respondTo.md`
        margin-top: 0;
        width: calc(66.666% - 20px);
      `}

      h4 {
        font-size: 28px;
        color: #e61274;
      }
    }

    .wishlist-body {
      padding-top: 15px;
      display: flex;
      flex-wrap: wrap;

      .wishlist-products {
        width: 100%;
        padding-right: 15px;

        ${respondTo.md`
          width: 75%;
        `}

        .products {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 15px;

          & > div {
            width: 100%;

            ${respondTo.md`
              width: 33.333%;
            `}
          }
        }
      }

      .add-more {
        width: 100%;
        border-left: 1px solid #e4e7f1;
        padding: 0 15px;

        span {
          display: block;
        }

        ${respondTo.md`
          width: 25%;
        `}
      }
    }

    .wishlist-empty {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .left-text {
        width: 100%;

        ${respondTo.md`
          width: 60%;
        `}

        span {
          display: block;
          margin-bottom: 15px;
        }
      }

      .right-icon {
        width: 40%;
        margin-top: 20px;
        text-align: right;
        display: none;

        ${respondTo.md`
          display: block;
        `}
      }

      .empty-footer {
        margin-top: 27px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      span {
        color: #e61274;
        font-size: 28px;
      }
    }

    .wishlist-user {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 14px;
      color: #e61274;
      display: none;

      ${respondTo.md`
        display: flex;
      `}

      .user-info {
        margin-left: 10px;

        p {
          text-transform: lowercase;

          &:first-letter {
            text-transform: uppercase;
          }
        }
      }
    }

    .wishlist-bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      margin-top: 20px;

      h4 {
        margin-bottom: 10px;
      }

      a {
        margin-top: 15px;
      }

      ${respondTo.md`
        margin-top: 0;

        h4 {
          margin-bottom: 0;
        }

        a {
          margin-top: 0;
        }
      `}
    }
  }

  .products-list {
    margin-top: 50px;

    h3 {
      text-align: center;
      font-size: 28px;
      margin-bottom: 20px;
    }

    .product-list {
      display: flex;
      flex-wrap: wrap;

      .single-product {
        width: 50%;
        margin-bottom: 20px;

        ${respondTo.md`
          width: 16.666%;
        `}
      }
    }
  }
`;

const Catalog = (props) => {
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.products.categories);

  const filtersForm = filtersform(9000);
  const { title, content } = useSelector(
    (state) =>
      state.cms.catalogo?.[0]?.contents.find((item) => item.name === "textHero")
        ?.i18l || {}
  );
  const userInfo = useSelector((state) => state.user.user.userinfo);
  const wishlist = useSelector((state) => state.user.user.wishlist);
  const products = useSelector((state) => state.products.products);
  const loading = useSelector((state) => state.products.loading);

  const filterProductsHandler = () => {
    dispatch(getProducts(formData));
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    datePickerHandler,
    resetFormHandler,
  } = useForm(filterProductsHandler, filtersForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        changedDatePicker={datePickerHandler}
        resetFormHandler={resetFormHandler}
        categories={categories}
        {...inp}
      />
    );
  });

  return (
    <CatalogWrapper>
      <div className="slide">
        <img
          src={process.env.PUBLIC_URL + "/images/catalog-background.jpg"}
          alt=""
        />
        <div className="content">
          <div>
            <div className="contentTitle">
              <Text as="h3">{title}</Text>
              <img
                src={process.env.PUBLIC_URL + "/icons/premiati-icon.svg"}
                alt=""
              />
            </div>

            <Text as="p" style={{ marginBottom: 15 }}>
              {content}
            </Text>
          </div>
        </div>
      </div>

      <div className="boxs">
        <div className="filter-box">
          <h3>Cerca nel catalogo</h3>
          <form onSubmit={formSubmitHandler}>
            {inputs}
            <div className="filter-buttons">
              <span onClick={() => resetFormHandler()}>
                Azzera filtri <X size={30} />
              </span>
              <Button
                type="submit"
                upper
                gradient={{ left: "#E61274", right: "#FF7DB9" }}
                hoverGradient={{ left: "#FF7DB9", right: "#FF7DB9" }}
              >
                Applica <ChevronRight size={30} />
              </Button>
            </div>
          </form>
        </div>

        <div className="wishlist-box">
          <ContentBorderBox title="La tua wishlist" color="#E61274">
            <div className="wishlist-body">
              {wishlist.length > 0 ? (
                <>
                  <div className="wishlist-products">
                    <span>
                      Ecco i premi più vicini a te, l’obiettivo è quasi
                      raggiunto
                    </span>
                    <div className="products">
                      {wishlist.slice(0, 3).map((product) => (
                        <ProductBox product={product} key={product.id} />
                      ))}
                    </div>
                  </div>

                  <div className="add-more">
                    <span>Aggiungi altri premi alla tua wishlist</span>
                    <a href="#catalog">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/add-more-products.svg"
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </>
              ) : (
                <div className="wishlist-empty">
                  <div className="left-text">
                    <span>
                      La tua wishlist è vuota. Aggiungi dei premi e tieni sotto
                      controllo quanto ti manca per raggiungerli.
                    </span>
                    <p>
                      <strong>
                        Aggiungere premi alla wishlist è molto semplice:{" "}
                      </strong>
                      clicca sull'icona del cuore presente sotto ogni immagine
                      per aggiungere nuovi regali nella tua lista. Se sei nella
                      scheda di dettaglio di un prodotto, invece, troverai
                      l'icona del cuore sopra l'immagine del premio in basso a
                      sinistra.
                      <br />
                      <br />
                      Se cambi idea e vuoi rimuovere un premio dalla wishlist,
                      ti basterà deselezionare l'icona del cuore e il premio
                      sarà automaticamente rimosso dalla lista.
                    </p>
                  </div>
                  <div className="right-icon">
                    <a href="#catalog">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/icons/empty-wishlist-icon.svg"
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="empty-footer">
                    <a href="#catalog">
                      <Button
                        upper
                        gradient={{ left: "#E61274", right: "#FF7DB9" }}
                        hoverGradient={{ left: "#FF7DB9", right: "#FF7DB9" }}
                      >
                        Vai al catalogo <ChevronDown size={30} />
                      </Button>
                    </a>
                  </div>
                </div>
              )}
            </div>

            <div className="wishlist-user">
              {userInfo && (
                <>
                  <Avatar shadow />
                  <div className="user-info">
                    <p>{userInfo.firstname}, hai</p>
                    <strong>{wishlist.length} premi in wishlist</strong>
                  </div>
                </>
              )}
            </div>

            {wishlist.length > 0 && (
              <div className="wishlist-bottom">
                <div>
                  <h4>
                    I tuoi premi preferiti
                    <br />
                    aspettano solo te
                  </h4>
                  <p>
                    Per scoprire tutti i premi del catalogo vai alla lista
                    completa
                  </p>
                </div>
                <Link to="/wishlist">
                  <Button
                    upper
                    gradient={{ left: "#E61274", right: "#FF7DB9" }}
                    hoverGradient={{ left: "#FF7DB9", right: "#FF7DB9" }}
                  >
                    Lista completa <ChevronRight size={30} />
                  </Button>
                </Link>
              </div>
            )}
          </ContentBorderBox>
        </div>
      </div>
      <div className="products-list" id="catalog">
        <h3>Il catalogo</h3>

        <div className="product-list">
          {loading ? (
            <Loader show={loading} />
          ) : (
            <>
              {products.map((prod) => (
                <ProductBox product={prod} key={prod.id} />
              ))}
            </>
          )}
        </div>
      </div>
    </CatalogWrapper>
  );
};

export default Catalog;
