import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import { Button, Text } from "../../components/Ui";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { useSelector } from "react-redux";
import moment from "moment";
const WishlistWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 270px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }

  .come-funziona-wrapper {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;

    .scopri {
      width: 100%;
      background-color: #ecf0fa;
      padding: 15px;
      border-radius: 15px;
      display: flex;
      flex-wrap: wrap;

      ${respondTo.md`
        width: 33.333%;
      `}

      h3 {
        margin-bottom: 20px;
        color: #eb5a3f;
        font-size: 28px;
      }

      p {
        margin-top: 35px;
      }

      h5 {
        margin-top: 50px;
        color: #f2a55b;
        font-size: 18px;
      }

      /* a {
        margin-bottom: auto;
      } */
    }

    .others {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding: 0 15px;

      ${respondTo.md`
        width: 66.666%;
      `}

      .col {
        width: 100%;

        ${respondTo.md`
          width: calc(50% - 20px);
        `}

        .col-image {
          text-align: center;
          margin-bottom: 15px;
        }

        &.premiati {
          h3,
          span {
            color: #e61274;
          }
        }
        &.conosciamoci {
          h3,
          span {
            color: #00abe5;
          }
        }
        &.club {
          h3,
          span {
            color: #fdca46;
          }
        }
        &.vinci {
          h3,
          span {
            color: #6cb967;
          }
        }

        h3 {
          font-size: 28px;
        }

        span {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }

  .table-points {
    margin-top: 50px;

    h3 {
      text-align: center;
      color: #e61274;
      font-size: 28px;
    }

    .table-wrapper {
      overflow-x: scroll;

      ${respondTo.md`
        overflow: hidden;
      `}
    }

    table {
      width: 100%;
      border-spacing: 0 1em;

      tbody {
        tr:nth-child(even) {
          background-color: #ecf0fa;
          border-radius: 10px;
          margin-bottom: 10px;
        }
      }

      thead {
        th {
          color: #ff7db9;
          font-size: 18px;
          font-weight: 300;
        }
      }

      th,
      td {
        text-align: left;
        padding: 10px 15px;
      }

      .first {
        font-weight: 700;
        text-transform: uppercase;
      }

      .pt {
        width: 90px;

        span {
          color: #e61274;
          border: 1px solid #e61274;
          border-radius: 5px;
          font-weight: 700;
          text-align: right;
          padding: 1px 5px;
          width: 70px;
          display: block;
        }
      }
    }
  }
`;

const HowToWork = (props) => {
  const { title, content } = useSelector(
    (state) =>
      state.cms.comeFunziona?.[0]?.contents?.find(
        (item) => item.name === "textHero"
      ).i18l || {}
  );
  const { title: titlePrimiati, content: contentPrimiati } = useSelector(
    (state) =>
      state.cms.comeFunziona?.[0]?.contents?.find(
        (item) => item.name === "premiati"
      )?.i18l || {}
  );
  const { content: sub_primiati } = useSelector(
    (state) =>
      state.cms.comeFunziona?.[0]?.contents?.find(
        (item) => item.name === "premiati"
      )?.subcontents?.[0]?.i18l || {}
  );
  const { title: titleConosciamoci, content: contentConosciamoci } =
    useSelector(
      (state) =>
        state.cms.comeFunziona?.[0]?.contents?.find(
          (item) => item.name === "conosciamoci"
        )?.i18l || {}
    );
  const { content: sub_conosciamoci } = useSelector(
    (state) =>
      state.cms.comeFunziona?.[0]?.contents?.find(
        (item) => item.name === "conosciamoci"
      )?.subcontents?.[0]?.i18l || {}
  );
  const { title: titleClub, content: contentClub } = useSelector(
    (state) =>
      state.cms.comeFunziona?.[0]?.contents?.find(
        (item) => item.name === "club"
      )?.i18l || {}
  );
  const { content: sub_club } = useSelector(
    (state) =>
      state.cms.comeFunziona?.[0]?.contents?.find(
        (item) => item.name === "club"
      )?.subcontents?.[0]?.i18l || {}
  );
  const { title: titleVinci, content: contentVinci } = useSelector(
    (state) =>
      state.cms.comeFunziona?.[0]?.contents?.find(
        (item) => item.name === "vinci"
      )?.i18l || {}
  );
  const { content: sub_vinci } = useSelector(
    (state) =>
      state.cms.comeFunziona?.[0]?.contents?.find(
        (item) => item.name === "vinci"
      )?.subcontents?.[0]?.i18l || {}
  );
  const { title: titleScopri, content: contentScopri } = useSelector(
    (state) =>
      state.cms.comeFunziona?.[0]?.contents?.find(
        (item) => item.name === "scopri"
      )?.i18l || {}
  );

  const checkIfPastTargetDate = () => {
    const targetDate = moment("2024-06-28T00:00:00");
    const now = moment();

    return now.isAfter(targetDate);
  };
  return (
    <WishlistWrapper>
      <div className="slide">
        <img
          src={process.env.PUBLIC_URL + "/images/come-funziona-slide.jpg"}
          alt=""
        />

        <div className="content">
          <div>
            <Text type="body" className="mb-20">
              {title}
            </Text>
            <Text>{content}</Text>
          </div>
        </div>
      </div>

      <div className="come-funziona-wrapper">
        <div className="scopri">
          <Text as="h3">{titleScopri}</Text>
          <img
            src={process.env.PUBLIC_URL + "/images/come-funziona-scopri.svg"}
            alt=""
          />
          <Text as="p">{contentScopri}</Text>

          {/* <h5>
            Non hai trovato le risposte che cercavi?
            <br />
            <br />
            Prova con la funzione “Cerca” nel menu in alto, consulta le nostre
            FAQ oppure scarica il regolamento completo.
          </h5> */}

          <Link
            to={
              process.env.PUBLIC_URL +
              "/pdf/MODIFICA_Reg_OP_MyLinkPlus_2021_2022_2023_2024_2025.pdf"
            }
            target="_blank"
          >
            <Button
              gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
              hoverGradient={{ left: "#FDCA46", right: "#FDCA46" }}
              upper
              style={{ marginTop: 30 }}
            >
              Scarica il regolamento
              <ChevronRight size={30} />
            </Button>
          </Link>
        </div>
        <div className="others">
          <div className="col premiati">
            <div className="col-image">
              <img
                src={process.env.PUBLIC_URL + "/icons/cf-premiati.svg"}
                alt=""
              />
            </div>

            <Text as="h3">{titlePrimiati}</Text>
            <Text as="span">{sub_primiati}</Text>
            <Text as="p">{contentPrimiati}</Text>
          </div>

          <div className="col conosciamoci">
            <div className="col-image">
              <img
                src={process.env.PUBLIC_URL + "/icons/cf-conosciamoci.svg"}
                alt=""
              />
            </div>

            <Text as="h3">{titleConosciamoci}</Text>
            <Text as="span">{sub_conosciamoci}</Text>
            <Text as="p">{contentConosciamoci}</Text>
          </div>

          <div className="col club">
            <div className="col-image">
              <img src={process.env.PUBLIC_URL + "/icons/cf-club.svg"} alt="" />
            </div>

            <Text as="h3">{titleClub}</Text>
            <Text as="span">{sub_club}</Text>
            <Text as="p">{contentClub}</Text>
          </div>

          <div className="col vinci">
            <div className="col-image">
              <img
                src={process.env.PUBLIC_URL + "/icons/cf-vinci.svg"}
                alt=""
              />
            </div>

            <Text as="h3">{titleVinci}</Text>
            <Text as="span">{sub_vinci}</Text>
            <Text as="p">{contentVinci}</Text>
          </div>
        </div>
      </div>

      <div className="table-points">
        <h3>Tutti i modi per avere + punti</h3>

        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Azioni premianti</th>
                <th>Punti</th>
                <th>Condizioni</th>
                <th>Modalità di assegnazione</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="first">Iscrizione al programma</td>
                <td className="pt">
                  <span>200 Pt</span>
                </td>
                <td>Iscrizione del Cliente al Programma</td>
                <td>Una Tantum - all'iscrizione al programma</td>
              </tr>
              <tr>
                <td className="first">Pagamento fattura bimestrale</td>
                <td className="pt">
                  <span>50 Pt</span>
                </td>
                <td>
                  Pagamento della fattura bimestrale relativa al servizio in
                  abbonamento
                </td>
                <td>Ricorrente - ogni 2 mesi</td>
              </tr>
              <tr>
                <td className="first">Ricarica 1 mese</td>
                <td className="pt">
                  <span>25 Pt</span>
                </td>
                <td>Acquisto ricarica</td>
                <td>Ricorrente - per ogni ricarica</td>
              </tr>
              <tr>
                <td className="first">Ricarica 3 mesi</td>
                <td className="pt">
                  <span>75 Pt</span>
                </td>
                <td>Acquisto ricarica</td>
                <td>Ricorrente - per ogni ricarica</td>
              </tr>
              <tr>
                <td className="first">Ricarica 5 mesi</td>
                <td className="pt">
                  <span>125 Pt</span>
                </td>
                <td>Acquisto ricarica</td>
                <td>Ricorrente - per ogni ricarica</td>
              </tr>
              <tr>
                <td className="first">Ricarica 12 mesi</td>
                <td className="pt">
                  <span>350 Pt</span>
                </td>
                <td>Acquisto ricarica</td>
                <td>Ricorrente - per ogni ricarica</td>
              </tr>
              <tr>
                <td className="first">Addebito ripetitivo automatico attivo</td>
                <td className="pt">
                  <span>25 Pt</span>
                </td>
                <td>
                  Attivazione addebito ripetitivo automatico su Area Clienti
                  MyLinkem
                </td>
                <td>
                  Ricorrente - per ogni mese di addebito ripetitivo avvenuto
                </td>
              </tr>
              <tr>
                <td className="first">Aggiornamento Email</td>
                <td className="pt">
                  <span>10 Pt</span>
                </td>
                <td>
                  Conferma o aggiornamento dell’indirizzo e-mail presente nei
                  dati personali dell’Area Clienti My Linkem o della APP
                  MyLinkem
                </td>
                <td>
                  Una tantum - vincolata all’aggiornamento del campo indirizzo
                  e-mail
                </td>
              </tr>
              <tr>
                <td className="first">Anniversario iscrizione al Programma</td>
                <td className="pt">
                  <span>100 Pt</span>
                </td>
                <td>Anniversario della data di iscrizione al Programma</td>
                <td>Ricorrente - ogni anno</td>
              </tr>
              <tr>
                <td className="first">
                  Acquisto di un servizio aggiuntivo o di un prodotto sullo shop
                  online di Linkem
                </td>
                <td className="pt">
                  <span>200 Pt</span>
                </td>
                <td>
                  Acquisto di un servizio aggiuntivo al contratto tra quelli
                  disponibili nella sezione “Servizi aggiuntivi per il tuo
                  contratto” dell’Area Clienti MyLinkem o acquisto di un
                  prodotto sullo shop online di Linkem accessibile dall’area
                  clienti o dalla APP MyLinkem
                </td>
                <td>
                  Ricorrente - per ogni servizio aggiuntivo al contratto o
                  prodotto acquistato
                </td>
              </tr>
              <tr>
                <td className="first">
                  Cliente ricaricabile che sottoscrive un abbonamento
                </td>
                <td className="pt">
                  <span>50 Pt</span>
                </td>
                <td>
                  Cliente ricaricabile che sottoscrive un contratto in
                  abbonamento
                </td>
                <td>
                  Una tantum - a seguito della sottoscrizione e attivazione di
                  un contratto in abbonamento superato il periodo di recesso di
                  14 gg
                </td>
              </tr>
              <tr>
                <td className="first">Compleanno</td>
                <td className="pt">
                  <span>20 Pt</span>
                </td>
                <td>Al compleanno del partecipante</td>
                <td>Ricorrente - ogni anno</td>
              </tr>
              <tr>
                <td className="first">
                  Partecipa al sondaggio nella sezione “Conosciamoci di +”
                </td>
                <td className="pt">
                  <span>10 Pt</span>
                </td>
                <td>Partecipazione ad un quiz o sondaggio</td>
                <td>Ricorrente - per ogni sondaggio completato</td>
              </tr>
              <tr>
                <td className="first">
                  Partecipazione Concorsi nella sezione “Vinci sempre di +”
                </td>
                <td className="pt">
                  <span>5 Pt</span>
                </td>
                <td>Partecipazione al concorso</td>
                <td>Ricorrente - per ogni partecipazione al concorso</td>
              </tr>
              <tr>
                <td className="first">Interazione con il “Club +”</td>
                <td className="pt">
                  <span>5 Pt</span>
                </td>
                <td>Richiesta di un vantaggio del Club +</td>
                <td>
                  Ricorrente - per ogni richiesta di un vantaggio del Club + e
                  fino a un massimo di 5
                </td>
              </tr>
              <tr>
                <td className="first">Accesso a MYLINKPLUS”</td>
                <td className="pt">
                  <span>4 Pt</span>
                </td>
                <td>
                  Accesso al portale MyLinkPlus - ogni 4 accessi per singolo
                  mese (1 accesso a settimana)
                </td>
                <td>
                  Ricorrente - ogni mese per i primi 6 mesi dall’iscrizione al
                  programma
                </td>
              </tr>
              <tr>
                <td className="first">Aging New</td>
                <td className="pt">
                  <span>5 Pt</span>
                </td>
                <td>
                  Essere nuovo cliente Linkem o cliente Linkem da oltre 1 anno e
                  fino a due anni
                </td>
                <td>Una Tantum</td>
              </tr>
              <tr>
                <td className="first">Aging 2+</td>
                <td className="pt">
                  <span>20 Pt</span>
                </td>
                <td>Essere cliente Linkem da oltre 2 anni e fino a 4 anni</td>
                <td>Una Tantum</td>
              </tr>
              <tr>
                <td className="first">Aging 5+</td>
                <td className="pt">
                  <span>50 Pt</span>
                </td>
                <td>Essere cliente Linkem da oltre 5 anni e fino a 9 anni</td>
                <td>Una Tantum</td>
              </tr>
              <tr>
                <td className="first">Aging 10+</td>
                <td className="pt">
                  <span>100 Pt</span>
                </td>
                <td>Essere cliente Linkem da oltre 10 anni</td>
                <td>Una Tantum</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </WishlistWrapper>
  );
};

export default HowToWork;
