import React, { useEffect, useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "./components/Ui";
import GenericScripts from "./components/GenericScripts";
import GlobalStyle from "./components/GlobalStyle";
import GlobalFont from "./components/GlobalFont";
import GDPR from "./components/GDPR";
import Favicon from "./components/Favicon";
import Routes from "./Routes/Routes";
import NotFound from "./views/NotFound";
import { connect } from "react-redux";
import * as actionCreators from "./store/actions";
import ReactGA from "react-ga4";
import { ThemeProvider } from "styled-components";
import { theme, configTheme } from "./theme/theme";
import { getFirstRoute } from "./utils/utils";
import "./fonts/font.css";

function App(props) {
  const r = getFirstRoute();

  const {
    getPromoInfo,
    getUserInfo,
    getViewer,
    getHighlightsPromo,
    isLogged,
    appConfig,
    appConfig: { lang, analytics, favicon },
    appError,
    loadingOauthSendToken,
    errorOauthSendoToken,
    getPartnerList,
    bootApp,
    getAllPromo,
    loading,
  } = props;

  const [googleAnalytics, setGoogleAnalytics] = useState(null);
  const [fav, setFav] = useState(null);
  const [fontSource, setFontSource] = useState(null);
  const [showGdpr, setShowGdpr] = useState(false);

  const [t, i18] = useTranslation();

  configTheme(appConfig);

  useEffect(() => {
    //getPromoInfo();
    getPartnerList();
    bootApp();
    //getViewer();
  }, []);

  useEffect(() => {
    setGoogleAnalytics(analytics);
  }, [analytics]);

  useEffect(() => {
    setFav(favicon);
  }, [favicon]);

  useEffect(() => {
    i18.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    if (isLogged) {
      getUserInfo();
      getAllPromo();
      getHighlightsPromo();
    }
  }, [isLogged]);

  useEffect(() => {
    if (appConfig) {
      if (appConfig.design) {
        if (appConfig.design.template_props.font_source) {
          setFontSource(appConfig.design.template_props.font_source);
        }
      }
      if (appConfig.i18l) {
        setShowGdpr(true);
      }
    }
  }, [appConfig]);

  let err = null;
  let errType = null;

  if (appError || errorOauthSendoToken) {
    if (appError) {
      err = appError;
      errType = "app";
    }
    if (errorOauthSendoToken) {
      err = errorOauthSendoToken;
      errType = "auth";
    }
  }

  // ReactGA.initialize(process.env.REACT_APP_GA);
  ReactGA.set({ anonymizeIp: true });

  return (
    <React.Fragment>
      <GlobalFont font_source={fontSource} />
      {/* <GoogleAnalytics ga={googleAnalytics} /> */}
      <Favicon favicon={fav} />
      <GenericScripts />
      <Loader
        initial
        show={loading || (r === "oauth" ? loadingOauthSendToken : null)}
      />

      {loading ? null : err ? (
        <NotFound type={errType} error={err} />
      ) : (
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loader show={true} full />}>
            <React.Fragment>
              <GlobalStyle font={theme.font} />
              <Routes />
              {/* {showGdpr ? <GDPR content={appConfig.i18l} /> : null} */}
            </React.Fragment>
          </Suspense>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.auth.token,
    appError: state.app.errorApp,
    appConfig: state.app.config,
    loadingOauthSendToken: state.auth.loadingOauthSendToken,
    errorOauthSendoToken: state.auth.errorOauthSendoToken,
    loading: state.bootApp.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getViewer: () => dispatch(actionCreators.getPromoViewer("get")),
    getPromoInfo: () => dispatch(actionCreators.getPromoInfo("get", "")),
    getAllPromo: () => dispatch(actionCreators.getAllPromo()),
    getHighlightsPromo: () => dispatch(actionCreators.getHighlightsPromo()),
    getPartnerList: () => dispatch(actionCreators.getPartnerList()),
    getUserInfo: () => dispatch(actionCreators.getUserInfo()),
    bootApp: () => dispatch(actionCreators.bootApp()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
