import * as actionTypes from "../actions/actionTypes";

const initialState = {
  intro: [],
  loadingintro: true,
  errorintro: false,
  footer: [],
  loadingfooter: true,
  errorfooter: false,
  how: [],
  coupon: [],
  convalida: [],
  home: [],
  comeFunziona: [],
  areaPartner: [],
  catalogo: [],
  promoSurvey: [],
  faq: [],
  contatti: [],
  vinciAncoraDiPiu: [],
};

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CMS_INTRO_START:
      return {
        ...state,
        loadingintro: true,
      };
    case actionTypes.GET_CMS_INTRO_SUCCESS:
      return {
        ...state,
        loadingintro: false,
        intro: action.data,
      };
    case actionTypes.GET_CMS_INTRO_ERROR:
      return {
        ...state,
        loadingintro: false,
        errorintro: action.error,
      };
    case actionTypes.GET_CMS_FOOTER_START:
      return {
        ...state,
        loadingfooter: true,
      };
    case actionTypes.GET_CMS_FOOTER_SUCCESS:
      return {
        ...state,
        loadingfooter: false,
        footer: action.content,
      };
    case actionTypes.GET_CMS_FOOTER_ERROR:
      return {
        ...state,
        loadingfooter: false,
        errorfooter: action.error,
      };
    case actionTypes.GET_CMS_HOW_SUCCESS:
      return {
        ...state,
        how: action.content,
      };
    case actionTypes.GET_CMS_COUPON_SUCCESS:
      return {
        ...state,
        coupon: action.content,
      };
    case actionTypes.GET_CMS_CONVALIDA_SUCCESS:
      return {
        ...state,
        convalida: action.content,
      };
    case actionTypes.GET_CMS_HOME_SUCCESS:
      return {
        ...state,
        home: action.content,
      };
    case actionTypes.GET_CMS_COME_FUNZIONA_SUCCESS:
      return {
        ...state,
        comeFunziona: action.content,
      };
    case actionTypes.GET_CMS_AREA_PARTNER_SUCCESS:
      return {
        ...state,
        areaPartner: action.content,
      };
    case actionTypes.GET_CMS_CATALOGO_SUCCESS:
      return {
        ...state,
        catalogo: action.content,
      };
    case actionTypes.GET_CMS_PROMO_SURVEY_SUCCESS:
      return {
        ...state,
        promoSurvey: action.content,
      };
    case actionTypes.GET_CMS_FAQ_SUCCESS:
      return {
        ...state,
        faq: action.content,
      };
    case actionTypes.GET_CMS_CONTATTI_SUCCESS:
      return {
        ...state,
        contatti: action.content,
      };
    case actionTypes.GET_CMS_VINCI_ANCORA_DI_PIU_SUCCESS:
      return {
        ...state,
        vinciAncoraDiPiu: action.content,
      };
    default:
      return state;
  }
};

export default cmsReducer;
