import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useForm from "../../hooks/useForm";
import { useState } from "react";
import moment from "moment";
import {
  RushAndWinForm,
  newRushAndWinForm,
} from "../../formsConfig/formsConfig";

import { Input, Button, Loader } from "../../components/Ui";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import { respondTo } from "../../theme/mixin";
import {
  activateUser,
  clearActivateUser,
  acceptConsent,
} from "../../store/actions/auth";
import { checkIfPastTargetDate } from "../../utils/utils";
const RushAndWin = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userInfo = useSelector((state) => state.user.user.userinfo);
  const firstAccessEnd = useSelector((state) => state.user.firstAccessEnd);
  const firstAccess = useSelector((state) => state.user.user.first_access_done);
  const loading = useSelector((state) => state.user.firstAccessLoading);
  const [firstAccessDone, setFirstAccessDone] = useState(false);

  const activateUserHanlder = (data) => {
    if (!checkIfPastTargetDate("2024-07-22T00:00:00")) {
      dispatch(activateUser(data.accept_launch_contest_rules_23));
    } else {
      dispatch(acceptConsent("accept_launch_contest_rules_24"));
    }
  };

  useEffect(() => {
    if (firstAccessEnd) {
      dispatch(clearActivateUser());
      history.push("/");
    }
  }, [firstAccessEnd]);
  const consents = useSelector((state) => state.user?.user?.consents);
  const acceptNewRegulationConcorso24 = consents?.some(
    (ele) => ele.consent_name === "accept_launch_contest_rules_24"
  );

  useEffect(() => {
    if (
      (!checkIfPastTargetDate("2024-07-22T00:00:00") &&
        userInfo &&
        userInfo.accept_launch_contest_rules_23 &&
        userInfo.first_access_done) ||
      (checkIfPastTargetDate("2024-07-22T00:00:00") &&
        userInfo &&
        acceptNewRegulationConcorso24 &&
        userInfo.first_access_done)
    ) {
      props.history.push("/");
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.first_access_done) {
      formData.privacy.valid = true;
      formData.rules.valid = true;
      setFirstAccessDone(true);
    }
  }, [userInfo]);

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    datePickerHandler,
    resetFormHandler,
  } = useForm(
    activateUserHanlder,
    checkIfPastTargetDate("2024-07-22T00:00:00")
      ? newRushAndWinForm()
      : RushAndWinForm()
  );

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        changedDatePicker={datePickerHandler}
        resetFormHandler={resetFormHandler}
        {...inp}
      />
    );
  });

  return (
    <div className="container">
      <Wrapper>
        <ContentBorderBox color="#EB5A3F" rushAndWin={true}>
          <div className="sides-wrapper">
            <div className="bg-image">
              <img
                src={
                  checkIfPastTargetDate("2024-07-22T00:00:00")
                    ? process.env.PUBLIC_URL +
                      "/images/rushandwinregisterednew.png"
                    : process.env.PUBLIC_URL +
                      "/images/rushandwinregistered.png"
                }
                alt=""
              />
            </div>
            <div className="left-side">
              <div className="access-header">
                <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
              </div>
              <h3>
                Ciao, <strong>{userInfo ? userInfo.firstname : null}</strong>!
              </h3>

              {checkIfPastTargetDate("2024-07-22T00:00:00") ? (
                <strong>
                  Partecipa al nostro nuovo concorso Vinci sempre di più e
                  festeggia con noi un nuovo anno insieme.
                </strong>
              ) : (
                <strong>
                  Partecipa al nostro nuovo concorso Vinci ancora di + e
                  festeggia con noi un nuovo anno insieme.
                </strong>
              )}
              {checkIfPastTargetDate("2024-07-22T00:00:00") ? (
                <p>
                  Partecipa alla Fase 1 del nostro nuovo concorso a premi{" "}
                  <strong>dal 22 luglio al 25 agosto 2024</strong>. <br />
                  Tutti coloro che <strong>
                    si iscriveranno al concorso
                  </strong>{" "}
                  parteciperanno all’
                  <strong>estrazione finale</strong> di{" "}
                  <strong>
                    12 fotocamere digitali istantanea KODAK Smile+
                  </strong>{" "}
                  e{" "}
                  <strong>
                    1 voucher viaggio Boscolo del valore di 5.000€!
                  </strong>
                  . <br />
                  <br />
                  In palio, per i più veloci,{" "}
                  <strong>
                    500 voucher per la sottoscrizione di un abbonamento annuale
                    ad una delle riviste digitali Grandi Clienti Mondadori.
                  </strong>
                  <br />
                  <br />E non è finita qui!{" "}
                  <strong>
                    Dal 2 settembre al 30 giugno 2025 potrai giocare tutti i
                    lunedì
                  </strong>{" "}
                  e provare ad aggiudicarti uno dei{" "}
                  <strong>fantastici premi</strong> in palio.
                </p>
              ) : (
                <p>
                  Partecipa al nuovo concorso a premi{" "}
                  <strong>dal 17 al 31 luglio</strong>: in palio per i{" "}
                  <strong>più veloci 500 voucher</strong> per scaricare una{" "}
                  <strong>guida Lonely Planet</strong>. <br />
                  <br />
                  Inoltre, tutti coloro che si{" "}
                  <strong>iscriveranno entro il 31 luglio</strong>{" "}
                  parteciperanno all’
                  <strong>estrazione finale</strong> di{" "}
                  <strong>12 droni</strong> per selfie{" "}
                  <strong>Air Selfie</strong> e{" "}
                  <strong>1 voucher Alpitour World del valore di 5000€</strong>.{" "}
                  <br />
                  <br />E non è finita qui!{" "}
                  <strong>
                    Dal 4 settembre potrai giocare tutti i lunedì e i giovedì
                  </strong>{" "}
                  e provare ad aggiudicarti uno dei{" "}
                  <strong>fantastici premi a sorpresa</strong> in palio.
                </p>
              )}
            </div>

            <div className="right-side">
              <h4>Consensi</h4>

              <p>
                {checkIfPastTargetDate("2024-07-22T00:00:00") ? (
                  <>
                    Per partecipare al concorso{" "}
                    <strong>Vinci sempre di più</strong>, dichiaro di aver letto
                    e accettato:
                  </>
                ) : (
                  <>
                    <strong>Per partecipare al </strong> concorso di lancio e al
                    concorso continuativo Vinci ancora di più, dichiaro di aver{" "}
                    <strong>letto</strong> e <strong>accettato</strong>
                  </>
                )}
                :
              </p>
              <div className="single-wrapper">
                <div className="single-consens revert-checkbox-order">
                  {inputs[2]}
                </div>
              </div>
              <form onSubmit={formSubmitHandler}>
                <Button
                  center
                  fullwidth
                  gradient={{ left: "#eb5c3f", right: "#FCC546" }}
                  upper
                  disabled={loading}
                >
                  {loading ? (
                    <Loader show={loading} color="#fff" inBtn />
                  ) : (
                    <>
                      Accedi
                      <ChevronRight size={30} />
                    </>
                  )}
                </Button>
                {/* <small>* campi obbligatori.</small> */}
              </form>
            </div>
          </div>
        </ContentBorderBox>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  margin-top: 30px;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;

  ${respondTo.sm`
    padding: 0;
    margin-top: 80px;
  `}

  .bg-image {
    position: absolute;
    left: 50%;
    transform: translatex(-40%);
    bottom: -22px;
    height: auto;
    width: auto;
    display: none;

    img {
      width: 100%;
      height: 100%;
    }

    ${respondTo.sm`
      display: block;
    `};
  }

  .single-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 50px;

    .single-consens {
      width: 100%;
      box-shadow: 0px 5px 15px #00000026;
      background-color: #fff;
      border-radius: 12px;
      margin-bottom: 15px;
      height: 44px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      > div {
        margin-bottom: 0;
        width: 100%;
      }
      &.revert-checkbox-order {
        label {
          justify-content: space-between;
          &:before {
            order: 1;
            margin: 0;
          }
        }
      }
      &.d-none {
        display: none;
      }
      a {
        color: #eb5a3f;
        font-weight: 700;
        font-size: 12px;
        text-decoration: underline;
      }
    }
  }

  .sides-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    ${respondTo.sm`
      align-items: center;
      flex-wrap: nowrap;
    `};

    .left-side {
      width: 330px;

      h3 {
        font-size: 21px;
        font-weight: 700;
        margin: 15px 0;

        strong {
          color: #eb5a3f;
        }
      }

      & > strong {
        display: block;
        margin-bottom: 15px;
        color: #f2a55b;
      }

      p {
        font-size: 14px;
      }
    }

    .right-side {
      width: 355px;
      z-index: 1;

      h4 {
        font-size: 18px;
        color: #fff;
        margin-bottom: 15px;
        @media (max-width: 768px) {
          color: black;
          margin-top: 40px;
        }
      }

      p {
        font-size: 12px;
        margin-bottom: 25px;
      }

      button {
        justify-content: center;
      }

      small {
        color: #ffcb98;
        font-weight: 700;
        margin-top: 15px;
        font-size: 10px;
        display: block;
        text-align: center;
      }
    }
  }
`;
export default RushAndWin;
