import * as actionTypes from "../actions/actionTypes";

const initialState = {
  user: {
    wishlist: [],
    cart: [],
  },
  profileImage: {
    loading: false,
    completed: false,
  },
  loading: true,
  error: null,
  activatedloading: true,
  activated: false,
  activatederror: null,
  userLogged: null,
  updateModal: false,
  updateError: false,
  deleteCompleted: false,
  firstAccessLoading: false,
  firstAccessEnd: false,
  firstAccessComplete: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTIVATE_USER_START:
      return {
        ...state,
        firstAccessLoading: true,
      };
    case actionTypes.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          first_access_done: action.first_access_done,
        },
        firstAccessLoading: false,
        firstAccessEnd: true,
        firstAccessComplete: true,
      };
    case actionTypes.CLEAR_ACTIVATE_USER:
      return {
        ...state,
        firstAccessLoading: false,
        firstAccessEnd: false,
      };
    case actionTypes.TO_WISHLIST_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          wishlist: action.wishlist,
        },
      };
    case actionTypes.TO_CART_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          cart: action.cart,
        },
      };
    case actionTypes.GET_USER_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
      };
    case actionTypes.GET_USER_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.USER_ACTIVATION_START:
      return {
        ...state,
        activatedloading: true,
      };
    case actionTypes.USER_ACTIVATION_SUCCESS:
      return {
        ...state,
        activated: true,
        activatedloading: false,
      };
    case actionTypes.USER_ACTIVATION_ERROR:
      return {
        ...state,
        activatederror: action.error,
        activatedloading: false,
      };
    case actionTypes.USER_ACTIVATION_CLEAR_ERROR:
      return {
        ...state,
        activatedloading: false,
        activatederror: null,
      };
    case actionTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        updateModal: true,
        user: { ...state.user, userinfo: action.user },
      };
    case actionTypes.EDIT_USER_ERROR:
      return {
        ...state,
        updateError: true,
      };
    case actionTypes.CLOSE_MODAL_EDIT:
      return {
        ...state,
        updateModal: false,
      };
    case actionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteCompleted: true,
      };
    case actionTypes.UPDATE_PROFILE_IMAGE_LOADING:
      return {
        ...state,
        profileImage: {
          loading: true,
          completed: false,
        },
      };
    case actionTypes.UPDATE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          userinfo: action.info,
        },
        profileImage: {
          loading: false,
          completed: true,
        },
      };
    case actionTypes.CLEAR_UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        profileImage: {
          loading: false,
          completed: false,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
