import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSlug } from "../../../../utils/utils";
import Dynamic from "../../Dynamics/Dynamic";
import styled from "styled-components";
import { Loader, Modal } from "../../../../components/Ui";
import { respondTo } from "../../../../theme/mixin";
import { marginBottom } from "styled-system";

const CardBagWrapper = styled.div`
  position: absolute;
  left: 50%;
  width: 60%;
  bottom: 5%;
  text-align: center;
  top: 40%;
  transform: translateX(-50%);
  z-index: 1;

  p {
    font-size: 16px;
    ${respondTo.sm`
      font-size: 24px;
    `}
  }
  @media screen and (max-width: 360px) {
    bottom: 0;
  }
  ${respondTo.md`
      // width: 45%;
       //top: 36%;
      //  bottom: 25%;
    `}
  .back-text {
    span {
      color: ${(props) => props.theme.primary};
      font-size: 24px;
      font-weight: 300;
    }
  }

  .uovo {
    text-align: center;
    position: relative;
  }
`;

const GameContent = styled.div`
  //cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  ${respondTo.md`
    
  `}

  .loader-wrapper {
    position: relative;

    ${respondTo.lg`
      top: 70px;
    `}
  }

  img {
    width: 130px;
    cursor: pointer;
    &.disabled {
      opacity: 0.5;
    }

    ${respondTo.md`
      width: 137px;
    `}
  }

  .back-button {
    position: absolute;
    left: 200px;
    bottom: -10px;
  }
`;

const CardBag = (props) => {
  const {
    type,
    onShowResult,
    typeInteraction,
    g: {
      id,
      i18l,
      interaction,
      preinteraction,
      log: { sid },
      already_won,
      gamePrivacyAccepted,
    },
    action: { data: dataAction, loading },
    canPlay,
    remaining_attempts,
  } = props;

  const loaderIw = useSelector((state) => state.game.loadingIw);
  const [attemptsError, setAttemptsError] = useState(false);
  const [finalCards, setFinalCards] = useState([]);
  const [action, setAction] = useState({ status: false, value: null });
  const [loader, setLoader] = useState(false);

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === "preinteraction") {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  useEffect(() => {
    if (dataAction.length > 0) {
      setTimeout(() => {
        onShowResult();
      }, 200);
    }
  }, [dataAction]);

  const clickCardHandler = (index) => {
    setAction({ status: true, value: index });
  };

  return (
    <>
      <CardBagWrapper>
        <GameContent>
          {loaderIw ? (
            <div className="loader-wrapper">
              <Loader show={loaderIw} />
            </div>
          ) : (
            <>
              {gamePrivacyAccepted && (
                <p
                  style={{
                    color: "#3a3c42",
                    marginBottom: "15px",
                  }}
                >
                  Clicca sull'aquilone
                  <br /> e scopri subito se hai vinto.
                </p>
              )}
              <img
                className={`back-bag ${!canPlay ? "disabled" : null}`}
                src={`${process.env.PUBLIC_URL}/images/context_top_to_play_lunedi.svg`}
                alt=""
                onClick={
                  /*  true
                  ? true */
                  remaining_attempts && remaining_attempts > 0 && !already_won
                    ? canPlay
                      ? () => clickCardHandler(0)
                      : null
                    : () => setAttemptsError(true)
                }
              />
            </>
          )}
        </GameContent>

        {action.status ? (
          <Dynamic
            id={id}
            sid={sid}
            slug={slug}
            section={typeInteraction}
            action={props.action}
            {...props}
            prizes={action.value}
          />
        ) : null}
      </CardBagWrapper>
      <Modal show={attemptsError} close={() => setAttemptsError(false)}>
        {already_won ? (
          <p>Hai già vinto un premio per questo gioco.</p>
        ) : (
          <p>Per oggi hai terminato i tentativi di gioco.</p>
        )}
      </Modal>
    </>
  );
};

export default CardBag;
