import React from "react";
import PropTypes from "prop-types";
import {
  CardHandler,
  UploadReceiptHandler,
  SurveyQuizHandler,
  BackHandler,
  ClickHandler,
} from "../../Handlers";
import BackHandlerSpring from "../../Handlers/BackHandler/BackHandlerSpring";
import useSpring from "../../../../hooks/useSpring";

const MechanicGame = (props) => {
  const {
    g: { interaction, id },
  } = props;
  const spring = useSpring({ id });
  let mechanicgame = null;
  switch (interaction.type) {
    case "survey-quiz":
      mechanicgame = <SurveyQuizHandler {...props} />;
      break;
    case "card":
      mechanicgame = <CardHandler {...props} />;
      break;
    case "prize-bag":
      mechanicgame = <BackHandlerSpring {...props} />;
      // <BackHandler {...props} />;
      break;
    case "click":
      mechanicgame = <ClickHandler {...props} />;

      break;
    case "upload-receipt":
      mechanicgame = <UploadReceiptHandler {...props} />;
      break;
    default:
      mechanicgame = <CardHandler {...props} />;
  }

  return <React.Fragment>{mechanicgame}</React.Fragment>;
};

MechanicGame.propTypes = {
  interaction: PropTypes.object,
};

export default MechanicGame;
