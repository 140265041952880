import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { X, Camera } from "@styled-icons/boxicons-regular";
import { Button, Loader } from "../Ui";
import * as actionCreators from "../../store/actions";
import { respondTo } from "../../theme/mixin";

const ChangeProfileWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #f08a77;
  z-index: 1001;

  .content {
    position: relative;
    width: calc(100% - 50px);
    max-width: 400px;
    background-color: #f2a55b;
    border-radius: 15px;
    padding: 15px;
    margin-top: 120px;
    box-shadow: 0px 5px 15px #00000026;

    .close-btn {
      cursor: pointer;
      border-radius: 50%;
      background-color: #f2a55b;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      position: absolute;
      top: -20px;
      right: -20px;
      box-shadow: 0px 5px 15px #00000026;
    }

    & > strong {
      color: #fff;
      font-size: 21px;
    }

    .image-preview {
      overflow: hidden;
      margin: 20px auto;
      width: 170px;
      height: 170px;
      border-radius: 50%;
      box-shadow: 0px 5px 15px #00000026;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image-placeholder {
        width: 75px;
        height: 75px;
      }
    }

    .avatars-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .avatar {
        width: 75px;
        height: 75px;
        margin-bottom: 15px;
        border-radius: 50%;
        box-shadow: 0px 5px 15px #0000004c;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .input-file {
      display: inline-block;

      .camera-file {
        cursor: pointer;
        width: 75px;
        height: 75px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fdca46;
        color: #eb5a3f;
        box-shadow: 0px 5px 15px #0000004c;

        strong {
          font-size: 12px;
          text-transform: uppercase;
          text-align: center;
          margin-top: -15px;
          line-height: 1;
        }
      }

      input {
        visibility: hidden;
        position: absolute;
      }
    }

    .button-wrapper {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }
`;

const ChangeProfileImageModal = ({ onClose }) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.user.profileImage.loading);
  const completed = useSelector((state) => state.user.profileImage.completed);

  const [selectedImage, setSelectedImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [preImage, setPreImage] = useState();

  const onChangeHandler = async (selectedFile) => {
    const objectUrl = URL.createObjectURL(selectedFile);
    setImagePreview(objectUrl);
    setSelectedImage(selectedFile);
    setPreImage();
  };

  const onChangePreHandler = (id) => {
    setPreImage(id);
    setImagePreview();
    setSelectedImage();
  };

  const uploadImage = () => {
    if (selectedImage || preImage) {
      dispatch(actionCreators.updateProfileImage(selectedImage, preImage));
    }
  };

  useEffect(() => {
    if (completed) {
      setImagePreview();
      setSelectedImage();
      onClose();
      dispatch(actionCreators.clearUpdateProfileImage());
    }
  }, [completed]);

  useEffect(() => {
    return () => {
      setImagePreview();
      setSelectedImage();
      setPreImage();
    };
  }, []);

  return (
    <ChangeProfileWrapper>
      <div className="content">
        <div className="close-btn" onClick={onClose}>
          <X size={30} color="#fff" />
        </div>
        <strong>Scegli il tuo avatar/foto</strong>

        <div className="image-preview">
          {imagePreview ? (
            <img src={imagePreview} alt="" />
          ) : preImage ? (
            <img
              src={process.env.PUBLIC_URL + `/images/avatars/${preImage}.png`}
              alt=""
            />
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/icons/image-placeholder.svg"}
              alt=""
              className="image-placeholder"
            />
          )}
        </div>

        <div className="avatars-list">
          <label htmlFor="file" className="input-file">
            <div className="camera-file">
              <Camera size={25} />
              <strong>Carica foto</strong>
            </div>

            <input
              id="file"
              type="file"
              accept="image/jpeg,image/jpg,image/png"
              onChange={(e) => onChangeHandler(e.currentTarget.files[0])}
            />
          </label>
          <div className="avatar" onClick={() => onChangePreHandler(1)}>
            <img
              src={process.env.PUBLIC_URL + "/images/avatars/1.png"}
              alt=""
            />
          </div>
          <div className="avatar" onClick={() => onChangePreHandler(2)}>
            <img
              src={process.env.PUBLIC_URL + "/images/avatars/2.png"}
              alt=""
            />
          </div>
          <div className="avatar" onClick={() => onChangePreHandler(3)}>
            <img
              src={process.env.PUBLIC_URL + "/images/avatars/3.png"}
              alt=""
            />
          </div>
          <div className="avatar" onClick={() => onChangePreHandler(4)}>
            <img
              src={process.env.PUBLIC_URL + "/images/avatars/4.png"}
              alt=""
            />
          </div>
          <div className="avatar" onClick={() => onChangePreHandler(5)}>
            <img
              src={process.env.PUBLIC_URL + "/images/avatars/5.png"}
              alt=""
            />
          </div>
          <div className="avatar" onClick={() => onChangePreHandler(6)}>
            <img
              src={process.env.PUBLIC_URL + "/images/avatars/6.png"}
              alt=""
            />
          </div>
          <div className="avatar" onClick={() => onChangePreHandler(7)}>
            <img
              src={process.env.PUBLIC_URL + "/images/avatars/7.png"}
              alt=""
            />
          </div>
        </div>

        <div className="button-wrapper">
          <Button
            upper
            gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
            onClick={() => uploadImage()}
          >
            {loading ? <Loader show={true} inBtn /> : "Conferma"}
          </Button>
        </div>
      </div>
    </ChangeProfileWrapper>
  );
};

export default ChangeProfileImageModal;
