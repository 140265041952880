import * as actionTypes from "./actionTypes";
import customaxios from "../../config/axios-refresh-token";
import { sendLogError } from "./error";
import { getUserInfo } from "./user";
import axios from "axios";
import customAxiosNm from "../../config/axios-refresh-token-no-multipart";

// Products
const getProductsLoading = () => {
  return {
    type: actionTypes.GET_PRODUCTS_LOADING,
  };
};

const getProductsSuccess = (products) => {
  return {
    type: actionTypes.GET_PRODUCTS_SUCCESS,
    products,
  };
};

const getAllProductsSuccess = (products) => {
  return {
    type: actionTypes.GET_ALL_PRODUCTS_SUCCESS,
    products,
  };
};

export const getAllProducts = () => {
  let endpoint = "products";
  return (dispatch) => {
    customaxios
      .post(endpoint)
      .then((result) => {
        if (result.status === 200) {
          dispatch(getAllProductsSuccess(result.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getProducts = (filter) => {
  const dataToSend = {};

  if (filter) {
    dataToSend.search = filter.search.value;
    dataToSend.category = filter.category.value;
    dataToSend.only_reacheble = filter.only_reacheble.value;
    dataToSend.min_points = filter.range.value.min;
    dataToSend.max_points = filter.range.value.max;
  }

  let endpoint = "products";
  return (dispatch) => {
    dispatch(getProductsLoading());
    customaxios
      .post(endpoint, dataToSend)
      .then((result) => {
        if (result.status === 200) {
          dispatch(getProductsSuccess(result.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// Single product
const getProductLoading = (product) => {
  return {
    type: actionTypes.GET_PRODUCT_LOADING,
  };
};

const getProductSuccess = (product) => {
  return {
    type: actionTypes.GET_PRODUCT_SUCCESS,
    product,
  };
};

export const getSingleProduct = (id) => {
  let endpoint = `products/${id}`;
  return (dispatch) => {
    dispatch(getProductLoading());
    axios
      .get(endpoint)
      .then((result) => {
        if (result.status === 200) {
          dispatch(getProductSuccess(result.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// Order product
const orderProductStart = () => {
  return {
    type: actionTypes.ORDER_PRODUCT_START,
  };
};

const orderProductSuccess = () => {
  return {
    type: actionTypes.ORDER_PRODUCT_SUCCESS,
  };
};

export const orderProduct = (cart) => {
  const products = [];

  cart.forEach((prod) => {
    const obj = {
      id: prod.id,
      qty: 1,
      points: prod.points,
    };
    products.push(obj);
  });
  let endpoint = "products/request";
  return (dispatch) => {
    dispatch(orderProductStart());
    customAxiosNm
      .post(endpoint, {
        products: products,
      })
      .then((result) => {
        if (result.status === 200) {
          dispatch(orderProductSuccess());
          dispatch(getUserInfo());
        }
      });
  };
};

export const openOrderModal = (product) => {
  return {
    type: actionTypes.OPEN_ORDER_MODAL,
    product,
  };
};

export const closeOrderModal = () => {
  return {
    type: actionTypes.CLOSE_ORDER_MODAL,
  };
};

export const clearOrder = () => {
  return {
    type: actionTypes.CLEAR_ORDER,
  };
};

export const getFiltersCategoriesSuccess = (categories) => {
  return {
    type: actionTypes.GET_FILTERS_CATEGORIES_SUCCESS,
    categories: categories,
  };
};

export const getFiltersCategories = () => {
  let endpoint = "catalog-categories";
  return (dispatch) => {
    customaxios
      .get(endpoint)
      .then((result) => {
        if (result.status === 200) {
          dispatch(getFiltersCategoriesSuccess(result.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const openWishlistModalAccess = () => {
  return {
    type: actionTypes.OPEN_WISHLIST_MODAL_ACCESS,
  };
};

export const closeWishlistModalAccess = () => {
  return {
    type: actionTypes.CLOSE_WISHLIST_MODAL_ACCESS,
  };
};
