import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function useSpring({ id } = {}) {
  const [showSpring, setshowSpring] = useState(true);
  /*   const { start, end } = useSelector(
    (state) =>
      state.game.games.find(
        (item) => +item.id === +process.env.REACT_APP_GAME_SPRING
      )?.settings || {}
  ); */

  const { start, end } = useSelector(
    (state) =>
      state.promo.allpromo
        .find((item) => item.name === "vinciancoradipiu")
        ?.games.find((item) => +item.id === +process.env.REACT_APP_GAME_SPRING)
        ?.settings || {}
  );
  useEffect(() => {
    if (start && end) {
      const start1 = new Date(start.replace(/-/g, "/")).getTime();
      const end1 = new Date(end.replace(/-/g, "/")).getTime();
      const today = new Date().getTime();
      if (id) {
        setshowSpring(
          Boolean(today >= start1 && today <= end1) &&
            (+id === 1 ||
              +id === 2 ||
              +id === +process.env.REACT_APP_GAME_SPRING)
        );
      } else {
        setshowSpring(Boolean(today >= start1 && today <= end1));
      }
    } else {
      setshowSpring(false);
    }
  }, [start, end]);

  return showSpring;
}

export default useSpring;
