import * as actionTypes from "../actions/actionTypes";

import { getUnique } from "../../utils/utils";

const initialState = {
  games: [],
  othergames: [],
  loadingGames: false,
  errorGames: null,
  loadingGetCode: false,
  getCode: null,
  //errorGetCode: null,
  errorGetCode: [],
  codes: [],
  links: [],
  linkRedirect: false,
  errorGetLink: null,
  loadingSendGameResult: true,
  errorSendGameResult: null,
  checkedGames: [],
  loadingCheckGame: false,
  errorCheckGame: null,
  checkedAllGames: false,
  loadingGetCodeAfterResult: false,
  errorGetCodeAfterResult: null,
  iw: [],
  loadingIw: false,
  //errorIw: null,
  errorIw: [],
  resultGame: [],
  loadingResultGame: false,
  errorResultGame: null,
  inGameFlow: false,
};

const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GAME_FLOW_START:
      return {
        ...state,
        inGameFlow: true,
      };
    case actionTypes.GAME_FLOW_END:
      return {
        ...state,
        inGameFlow: false,
      };
    case actionTypes.GET_GAMES_START:
      return {
        ...state,
        loadingGames: true,
        error: null,
      };
    case actionTypes.GET_GAMES_SUCCESS:
      return {
        ...state,
        loadingGames: false,
        error: null,
        games: action.games,
      };
    case actionTypes.GET_OTHER_GAMES_SUCCESS:
      return {
        ...state,
        loadingGames: false,
        error: null,
        othergames: action.othergames,
      };
    case actionTypes.GET_GAMES_ERROR:
      return {
        ...state,
        loadingGames: false,
        error: action.error,
      };
    case actionTypes.GET_CODE_START:
      return {
        ...state,
        loadingGetCode: true,
      };
    case actionTypes.GET_CODE_SUCCESS:
      let newCodes = state.codes.concat(action.code);
      return {
        ...state,
        loadingGetCode: false,
        codes: [...getUnique(newCodes, "id")],
        //codes: state.codes.concat(action.code),
      };
    case actionTypes.GET_CODE_ERROR:
      return {
        ...state,
        loadingGetCode: false,
        //errorGetCode: action.error,
        errorGetCode: state.errorGetCode.concat(action.error),
      };
    case actionTypes.GET_CODE_CLEAN_ERROR:
      return {
        ...state,
        loadingGetCode: false,
        //errorGetCode: null,
        errorGetCode: state.errorGetCode.filter(
          (item) => item.id !== action.id
        ),
      };
    case actionTypes.CLEAN_CODE_DATA:
      return {
        ...state,
        loadingGetCode: false,
        codes: state.codes.filter((item) => item.id !== action.id),
      };
    case actionTypes.GET_LINK_START:
      return {
        ...state,
        loadingGetLink: true,
        linkRedirect: false,
      };
    case actionTypes.GET_LINK_SUCCESS:
      return {
        ...state,
        loadingGetLink: false,
        links: state.links.concat(action.link),
        linkRedirect: true,
      };
    case actionTypes.GET_LINK_ERROR:
      return {
        ...state,
        loadingGetLink: false,
        errorGetLink: action.error,
        linkRedirect: false,
      };
    case actionTypes.SEND_GAME_RESULT_START:
      return {
        ...state,
        loadingSendGameResult: true,
        errorSendGameResult: null,
      };
    case actionTypes.SEND_GAME_RESULT_SUCCESS:
      return {
        ...state,
        loadingSendGameResult: false,
        errorSendGameResult: null,
      };
    case actionTypes.SEND_GAME_RESULT_ERROR:
      return {
        ...state,
        loadingSendGameResult: false,
        errorSendGameResult: action.error,
      };
    case actionTypes.CHECK_GAME_PLAYED_START:
      return {
        ...state,
        loadingCheckGame: true,
      };
    case actionTypes.CHECK_GAME_PLAYED_SUCCESS:
      return {
        ...state,
        loadingCheckGame: false,
        checkedGames: state.checkedGames.concat(action.id),
      };
    case actionTypes.CHECK_GAME_PLAYED_ERROR:
      return {
        ...state,
        loadingCheckGame: false,
        errorCheckGame: action.error,
      };
    case actionTypes.CHECK_GAME_PLAYED_RESET:
      return {
        ...state,
        checkedGames: [],
      };
    case actionTypes.CHECK_ALL_GAMES_PLAYED_SUCCESS:
      return {
        ...state,
        checkedAllGames: true,
      };
    case actionTypes.GET_CODE_AFTER_GAME_START:
      return {
        ...state,
        loadingGetCodeAfterResult: true,
      };
    case actionTypes.GET_CODE_AFTER_GAME_SUCCESS:
      return {
        ...state,
        loadingGetCodeAfterResult: false,
      };
    case actionTypes.GET_CODE_AFTER_GAME_ERROR:
      return {
        ...state,
        loadingGetCodeAfterResult: false,
      };
    case actionTypes.GET_IW_START:
      return {
        ...state,
        loadingIw: true,
      };
    case actionTypes.GET_IW_SUCCESS:
      return {
        ...state,
        loadingIw: false,
        iw: state.iw.concat(action.iw),
      };
    case actionTypes.GET_IW_ERROR:
      return {
        ...state,
        loadingIw: false,
        errorIw: action.error,
      };
    case actionTypes.GET_IW_CLEAN_ERROR:
      return {
        ...state,
        loadingIw: false,
        errorIw: null,
      };
    case actionTypes.CLEAN_IW_DATA:
      return {
        ...state,
        loadingIw: false,
        iw: state.iw.filter((item) => item.id !== action.id),
      };
    case actionTypes.SAVE_GAME_RESULT_START: {
      return {
        ...state,
        loadingResultGame: true,
      };
    }
    case actionTypes.SAVE_GAME_RESULT_SUCCESS: {
      return {
        ...state,
        loadingResultGame: false,
        resultGame: state.resultGame.concat(action.result),
      };
    }
    case actionTypes.SAVE_GAME_RESULT_ERROR: {
      return {
        ...state,
        loadingResultGame: false,
        errorResultGame: action.error,
      };
    }
    case actionTypes.SAVE_GAME_RESULT_CLEAN_ERROR: {
      return {
        ...state,
        loadingResultGame: false,
        errorResultGame: null,
      };
    }
    default:
      return state;
  }
};

export default gameReducer;
