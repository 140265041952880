import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import { Button, Text } from "../Ui";
import { Link } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { useSelector } from "react-redux";
import moment from "moment";
import { checkIfPastTargetDate } from "../../utils/utils";
const LogosWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 90px 15px 0 15px;

  .slick-track {
    align-items: flex-start !important;
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex !important;
    justify-content: center;

    .slick-active button {
      background-color: #fdca46;
    }

    button {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.3);
      border: none;
      font-size: 0;
      margin: 0 5px;
      cursor: pointer;
    }
  }

  ${respondTo.md`
    padding: 0;
  `}

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-list > div {
    margin-left: 0;
  }
`;

const Logo = styled.div`
  img {
    width: 100%;
    &.new {
      min-height: 540px;
      object-fit: cover;
    }
  }
`;

const Content = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  margin-top: 15px;

  ${respondTo.md`
    top: 0;
    position: absolute;
    width: 100%;
    max-width: 380px;
    height: 100%;
    margin-top: 0;
    &.new {
      top: unset;
      bottom: 2px;
      align-items: flex-end;
    }
  `}

  div {
    h3 {
      font-size: 26px;
      font-weight: 300;
    }
  }
`;

const HomeSlider = () => {
  const options = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { title, content } = useSelector(
    (state) => state.cms.home?.[0]?.contents?.[0].i18l || {}
  );

  return (
    <LogosWrapper>
      <Slider {...options}>
        <div>
          <Logo>
            <img
              className={
                checkIfPastTargetDate("2024-07-22T00:00:00") ? "new" : ""
              }
              src={
                checkIfPastTargetDate("2024-07-22T00:00:00")
                  ? process.env.PUBLIC_URL + "/images/home-slide-concorso.png"
                  : process.env.PUBLIC_URL +
                    "/images/home-slide-concorso-past.png"
              }
              alt=""
            />
          </Logo>
          <Content
            className={
              checkIfPastTargetDate("2024-07-22T00:00:00") ? "new" : ""
            }
          >
            <div>
              <Text bold as="h3">
                {title}
              </Text>
              <Text margin="20px 0 15px 0">{content}</Text>
              <a
                href={
                  checkIfPastTargetDate("2024-07-22T00:00:00")
                    ? process.env.PUBLIC_URL +
                      "/pdf/2024_TISCALI-VINCI_SEMPRE_DI_PIU_con_rushwin_instant_win_rev privacy_04-07-2024_DEF.pdf"
                    : process.env.PUBLIC_URL +
                      "/pdf/Regolamento_Linkem_Vinci_ancora_di_più.pdf"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  upper
                  gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
                  hoverGradient={{ left: "#FDCA46", right: "#FDCA46" }}
                >
                  Scarica il regolamento
                  <ChevronRight size={30} />
                </Button>
              </a>
            </div>
          </Content>
        </div>

        {/* <div>
          <Logo>
            <img
              src={process.env.PUBLIC_URL + "/images/home-slider.jpg"}
              alt=""
            />
          </Logo>
          <Content>
            <div>
              <h3>+ Spazio alle tue passioni.</h3>
              <p style={{ marginBottom: 15, marginTop: 20 }}>
                <strong>+ Contenuti, + vantaggi, + premi</strong> e molte{" "}
                <strong>+ possibilità di vincere: MYLINKPLUS</strong> da oggi ti
                dà ancora di +.
              </p>
              <Link to="/come-funziona">
                <Button
                  upper
                  gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
                  hoverGradient={{ left: "#FDCA46", right: "#FDCA46" }}
                >
                  Scopri di più
                  <ChevronRight size={30} />
                </Button>
              </Link>
            </div>
          </Content>
        </div> */}
      </Slider>
    </LogosWrapper>
  );
};

export default HomeSlider;
