import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import {
  addToWishlist,
  removeFromWishlist,
  addToCart,
} from "../../store/actions/wishlist";
import { ChevronDown } from "@styled-icons/boxicons-regular";
import {
  openOrderModal,
  openWishlistModalAccess,
} from "../../store/actions/products";
import { Button, Modal } from "../../components/Ui";

const BoxWrapper = styled.div`
  position: relative;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;

  &:hover .box-image:before {
    opacity: 1;
    visibility: visible;
    transition: all 0.2s;
  }

  &:hover .box-info .box-name {
    color: #e61274;
  }

  .box-image {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 160px;
    height: 160px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 5px 15px #00000029;

    &:before {
      content: "";
      opacity: 0;
      visibility: hidden;
      width: 100%;
      height: 100%;
      background-color: rgba(230, 18, 116, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      transition: all 0.2s;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .set-wrapper {
    position: absolute;
    top: 13px;
    right: 15px;
    cursor: pointer;
    text-align: right;
    z-index: 100;

    .set-list {
      background-color: #fff;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0px 5px 15px #00000026;
      width: 225px;
      text-align: center;

      &:last-child {
        border-bottom: none;
      }

      li {
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);

        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }
      }
    }
  }

  .box-info {
    width: 100%;
    position: relative;
    top: -20px;
    text-align: center;

    .add-to {
      background-color: #e61274;
      margin: 0 auto;
      border-radius: 50px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
      overflow: hidden;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        position: relative;

        &:hover {
          background-color: rgba(0, 0, 0, 0.15);
        }
      }

      .add-to-cart:after {
        content: "";
        width: 1px;
        height: 38px;
        display: block;
        background-color: rgba(0, 0, 0, 0.1);
        right: -1px;
        position: absolute;
      }
    }

    p {
      color: #ff7db9;
    }

    strong {
      display: block;
    }

    .box-name {
      text-transform: uppercase;
      color: #3a3c42;
    }

    .box-points {
      color: #ff7db9;
    }
  }
`;

const ProductBox = ({ product, paddingBottom }) => {
  const dispatch = useDispatch();

  const wishlist = useSelector((state) => state.user.user.wishlist);
  const isLogged = useSelector((state) => state.auth.token);

  const [inWishlist, setInWishlist] = useState(false);
  const [openSets, setOpenSets] = useState(false);
  const [selectedSet, setSelectedSet] = useState();
  const [emptySet, setEmptySet] = useState(false);
  //const [modalAccess, setModalAccess] = useState(false);

  const addToWishlistHandler = (productId) => {
    dispatch(addToWishlist(productId));
  };

  const removeFromWishlistHandler = (productId) => {
    dispatch(removeFromWishlist(productId));
  };

  /* const openOrderModalHandler = () => {
    dispatch(openOrderModal(product));
  }; */

  useEffect(() => {
    if (wishlist && product) {
      let idToCompare = selectedSet ? selectedSet.id : product.id;
      const exist = wishlist.find((el) => el.id === idToCompare);

      if (exist) {
        setInWishlist(true);
      } else {
        setInWishlist(false);
      }
    }
  }, [wishlist, product, selectedSet]);

  useEffect(() => {
    if (product.set) {
      if (product.set.length > 0) {
        setSelectedSet(product.set[0]);
      } else {
        setEmptySet(true);
      }
    }
  }, [product]);

  return product && !emptySet ? (
    <div
      className="single-product"
      style={{ paddingBottom: paddingBottom && 80 }}
    >
      <BoxWrapper>
        <Link to={`/prodotto/${selectedSet ? selectedSet.id : product.id}`}>
          <div className="box-image">
            <img src={product.image} alt="" />
          </div>
        </Link>
        <div className="box-info">
          {product.set && product.set.length > 0 && (
            <div className="set-wrapper">
              <span onClick={() => setOpenSets(!openSets)}>
                <ChevronDown size={30} />
              </span>
              {openSets && (
                <ul className="set-list">
                  {product.set.map((item, setIndex) => (
                    <li
                      key={setIndex}
                      onClick={() => {
                        setSelectedSet(item);
                        setOpenSets(false);
                      }}
                    >
                      {item.short_description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          <div className="add-to">
            <div
              className="add-to-cart"
              //onClick={() => openOrderModalHandler()}
              onClick={() =>
                dispatch(addToCart(selectedSet ? selectedSet.id : product.id))
              }
            >
              <img
                src={process.env.PUBLIC_URL + "/icons/addToCartWhite.svg"}
                alt=""
              />
            </div>
            <div
              className="add-to-wishlist"
              onClick={() =>
                !isLogged
                  ? dispatch(openWishlistModalAccess())
                  : inWishlist
                  ? removeFromWishlistHandler(
                      selectedSet ? selectedSet.id : product.id
                    )
                  : addToWishlistHandler(
                      selectedSet ? selectedSet.id : product.id
                    )
              }
            >
              {inWishlist ? (
                <img
                  src={process.env.PUBLIC_URL + "/icons/in-favorite-icon.svg"}
                  alt=""
                />
              ) : (
                <img
                  src={process.env.PUBLIC_URL + "/icons/favorite-icon.svg"}
                  alt=""
                />
              )}
            </div>
          </div>
          <Link to={`/prodotto/${selectedSet ? selectedSet.id : product.id}`}>
            <p>{product.brand}</p>
            <strong className="box-name">
              {selectedSet
                ? selectedSet.short_description
                : product.short_description}
            </strong>
            <strong className="box-points">
              {selectedSet ? selectedSet.points : product.points} Pt
            </strong>
          </Link>
        </div>
      </BoxWrapper>
    </div>
  ) : null;
};

export default ProductBox;
